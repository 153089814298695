import { useEffect } from "react";
import { useIntl } from "react-intl";

export function useSetBreadcrumbs(supplierName: string) {
    const { formatMessage } = useIntl();
    useEffect(() => {
        const basePayload = [
            {
                text: formatMessage({ defaultMessage: "Suppliers", description: "Breadcrumb for suppliers module" }),
                href: "/suppliers/overview",
            },
        ];

        const payload = [
            ...basePayload,
            {
                text: supplierName,
            },
        ];

        const data = {
            source: "ignite-breadcrumbs",
            payload,
        };

        window.postMessage(data, window.location.origin);
    }, [formatMessage, supplierName]);
}
