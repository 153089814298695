export const TruckIcon: React.FC = () => {
    return (
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V4H17.5C17.7884 4 18.0627 4.12448 18.2526 4.3415L21.7494 8.33787C21.9054 8.51424 22 8.74607 22 9V15C22 15.5523 21.5523 16 21 16H19.8293C19.4175 17.1652 18.3062 18 17 18C15.6938 18 14.5825 17.1652 14.1707 16H7.32929C6.91746 17.1652 5.80622 18 4.5 18C3.19378 18 2.08254 17.1652 1.67071 16H1C0.447715 16 0 15.5523 0 15V3ZM2 11V13.3411C2.53746 12.5328 3.45651 12 4.5 12C5.80622 12 6.91746 12.8348 7.32929 14H12V11H2ZM12 9H2V3C2 2.44772 2.44772 2 3 2H11C11.5523 2 12 2.44772 12 3V9ZM14 11V14H14.1707C14.5825 12.8348 15.6938 12 17 12C18.3062 12 19.4175 12.8348 19.8293 14H20V11H14ZM18 15C18 14.4477 17.5523 14 17 14C16.4477 14 16 14.4477 16 15C16 15.5523 16.4477 16 17 16C17.5523 16 18 15.5523 18 15ZM14 9V6H17.0462L19.6712 9H14ZM4.5 14C3.94772 14 3.5 14.4477 3.5 15C3.5 15.5523 3.94772 16 4.5 16C5.05228 16 5.5 15.5523 5.5 15C5.5 14.4477 5.05228 14 4.5 14Z"
                fill="#45484F"
            />
        </svg>
    );
};
