/* eslint-disable react/jsx-no-literals */
import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { CustomTooltip } from "@/components/Common/CustomTooltip";
import { renderCustomLegend } from "@/components/Common/utils";

import { formatSpend } from "../Spend/utils";

import { FinancialData } from ".";

interface RevenueEbitChartProps {
    financialData: FinancialData[];
    config: {
        revenuesMax: number;
        ebitMarginMin: number;
        ebitMarginMax: number;
    };
}

export const RevenueEbitChart: React.FC<RevenueEbitChartProps> = ({ financialData, config }) => {
    const theme = useTheme();
    const { formatNumber, formatMessage } = useIntl();
    const { revenuesMax, ebitMarginMin, ebitMarginMax } = config;

    const [isBarHovered, setIsBarHovered] = useState(false);
    const handleMouseOver = () => setIsBarHovered(true);
    const handleMouseOut = () => setIsBarHovered(false);
    return (
        <Card>
            <CardContent onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <Stack marginBottom={3}>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financial data" />
                    </Typography>
                </Stack>
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart data={financialData}>
                        <XAxis
                            dataKey="year"
                            tick={{
                                fill: theme.palette.grey[600],
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 14,
                            }}
                            tickMargin={10}
                        />
                        <YAxis
                            yAxisId="left"
                            orientation="left"
                            domain={[0, revenuesMax + revenuesMax * 0.05]}
                            tick={{
                                fill: theme.palette.grey[600],
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 14,
                            }}
                            tickFormatter={(val: number) => {
                                return formatNumber(val, {
                                    notation: "compact",
                                    maximumFractionDigits: 1,
                                    maximumSignificantDigits: 2,
                                });
                            }}
                            tickCount={11}
                            tickLine={false}
                            axisLine={false}
                            interval="preserveStartEnd"
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            tick={{
                                fill: theme.palette.grey[600],
                                fontFamily: theme.typography.fontFamily,
                                fontSize: 12,
                            }}
                            tickFormatter={(val: number) => {
                                return formatNumber(val, {
                                    style: "percent",
                                    maximumFractionDigits: 1,
                                });
                            }}
                            domain={[ebitMarginMin - 0.05, ebitMarginMax + 0.05]}
                            tickLine={false}
                            axisLine={false}
                            interval="preserveStartEnd"
                        />
                        <Tooltip
                            content={
                                <CustomTooltip
                                    active={false}
                                    payload={[]}
                                    label=""
                                    chartInfo={({ payload, label }) => {
                                        const data = payload[0].payload;
                                        const revenueString = formatSpend(Number(data.revenue), true, data.currency);
                                        const ebitString = `${(data.ebitMargin * 100).toFixed(2)}%`;
                                        return (
                                            <Stack>
                                                <Typography variant="textXs">{label}</Typography>
                                                <Typography variant="textXs">
                                                    <FormattedMessage defaultMessage="Revenue" />
                                                </Typography>
                                                <Typography variant="textLg">{revenueString}</Typography>
                                                <Typography variant="textXs">
                                                    <FormattedMessage defaultMessage="EBIT margin" />
                                                </Typography>
                                                <Typography variant="textLg">{ebitString}</Typography>
                                            </Stack>
                                        );
                                    }}
                                    color={theme.palette.secondary.light}
                                />
                            }
                        />
                        <Legend
                            content={renderCustomLegend([
                                {
                                    title: formatMessage({ defaultMessage: "Revenue" }),
                                    color: theme.palette.secondary.light,
                                },
                                {
                                    title: formatMessage({ defaultMessage: "EBIT margin" }),
                                    color: theme.palette.text.primary,
                                },
                            ])}
                        />
                        <CartesianGrid strokeDasharray="0" vertical={false} />
                        <Bar
                            name={formatMessage({ defaultMessage: "Revenue" })}
                            barSize={80}
                            isAnimationActive={true}
                            yAxisId="left"
                            dataKey="revenue"
                            fill={theme.palette.secondary.light}
                            opacity={isBarHovered ? 0.6 : 1}
                            activeBar={{ opacity: 1 }}
                        />
                        <Line
                            name={formatMessage({ defaultMessage: "EBIT margin" })}
                            yAxisId="right"
                            type="linear"
                            dataKey="ebitMargin"
                            stroke={theme.palette.text.primary}
                            dot={{ stroke: "black", strokeWidth: 2 }}
                            activeDot={{ stroke: "black", strokeWidth: 3 }}
                            animationDuration={500}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};
