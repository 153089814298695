import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Paper, Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import { AnswerWithEmail } from "../InternalAnswers";

type InternalMetaAnswerTableProps = {
    answers: AnswerWithEmail[];
    valueCellProps?: {
        maxRating?: number;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueCellRenderer: FunctionComponent<any>;
};

export const InternalAnswerList: React.FC<InternalMetaAnswerTableProps> = ({
    answers,
    valueCellProps = {},
    valueCellRenderer: ValueCellRenderer,
}) => {
    const { formatMessage } = useIntl();

    const countString = `${answers.length} ${answers.length === 1 ? formatMessage({ defaultMessage: "response" }) : formatMessage({ defaultMessage: "responses" })}`;
    return (
        <Stack spacing={2}>
            <Typography variant="textXs">{countString}</Typography>

            {answers.map((answer) => {
                const answerDate = `${formatMessage({ defaultMessage: "Submitted" })}: ${formatDateAsUTC(answer.createdAt || "")}`;
                return (
                    <Paper key={answer.id}>
                        <Stack margin={2}>
                            {ValueCellRenderer({ value: answer.value, ...valueCellProps })}
                            <Stack direction="row" justifyContent="space-between" marginTop={2}>
                                <Typography variant="textSm">{answer.receiverEmail}</Typography>
                                <Typography variant="textXs">{answerDate}</Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                );
            })}
        </Stack>
    );
};
