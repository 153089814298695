import { Button, Stack, Typography } from "@mui/material";

import { GraphEmptyState } from "@/assets/GraphEmptyState";

type NoDataProps = {
    height?: string;
    title?: string;
    message?: string;
    buttonText?: string;
    buttonOnClick?: () => void;
    linkElement?: React.ReactNode;
};

export const NoData: React.FC<NoDataProps> = ({ height, title, message, buttonText, buttonOnClick, linkElement }) => {
    return (
        <Stack
            sx={{
                height: height,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
            }}
        >
            <Stack alignItems="center" spacing={2} padding={2}>
                <GraphEmptyState />
                {title && (
                    <Typography variant="textXl" sx={{ mt: 2, mb: 2 }}>
                        {title}
                    </Typography>
                )}
                {message && (
                    <Typography fontWeight={400} variant="textSm" textAlign="center">
                        {message}
                    </Typography>
                )}
                {buttonText && buttonOnClick && (
                    <Button color="secondary" size="small" onClick={buttonOnClick}>
                        {buttonText}
                    </Button>
                )}
                {linkElement}
            </Stack>
        </Stack>
    );
};
