import { Button, Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { Documents_SupplierFragment } from "@/gql/graphql";

import { AllDocumentsModal } from "./AllDocumentsModal";
import { DocumentsList } from "./DocumentsList";
import { UploadButton } from "./UploadButton";

type Props = {
    isEditor: boolean;
    supplier: Documents_SupplierFragment;
    refetch: () => void;
    loading: boolean;
};

graphql(`
    fragment Documents_Supplier on Supplier {
        id
        files {
            ...DocumentsList_SupplierFile
        }
        assessmentsFiles {
            ...DocumentsList_AssessmentsFile
        }
    }
`);

export const Documents: React.FC<Props> = ({ isEditor, supplier, refetch, loading }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openViewAll, setOpenViewAll] = useState(false);

    const documentsSorted = [...supplier.files, ...supplier.assessmentsFiles].sort((a, b) => {
        const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
        return createdAtB - createdAtA;
    });
    const enableViewAll = documentsSorted.length > 3;

    return (
        <Stack spacing={2} pt={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="textXl" fontWeight={500}>
                    <FormattedMessage defaultMessage="Documents" description="Documents header" />
                </Typography>

                <Button color="secondary" size="2xsmall" disabled={!enableViewAll} onClick={() => setOpenViewAll(true)}>
                    <FormattedMessage defaultMessage="View all" description="View all documents button" />
                </Button>
            </Stack>
            {documentsSorted.length > 0 ? (
                <DocumentsList
                    files={documentsSorted.slice(0, 3)}
                    isLoading={isLoading}
                    supplierId={supplier.id}
                    isEditor={isEditor}
                />
            ) : loading ? (
                <Card>
                    <CardContent>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack spacing={2}>
                                <Skeleton width="171px" height="17px" sx={{ borderRadius: 0.5 }} />
                                <Skeleton width="365px" height="17px" sx={{ borderRadius: 0.5 }} />
                            </Stack>
                            <Skeleton width="126px" height="40px" sx={{ borderRadius: 0.5 }} />
                        </Stack>
                    </CardContent>
                </Card>
            ) : (
                <Card sx={{ padding: 2, borderRadius: 1 }}>
                    <Typography variant="textSm" color={(theme) => theme.palette.primary.main}>
                        <FormattedMessage defaultMessage="No documents found" />
                    </Typography>
                </Card>
            )}
            <UploadButton isEditor={isEditor} supplierId={supplier.id} setIsLoading={setIsLoading} refetch={refetch} />
            <AllDocumentsModal
                documents={documentsSorted}
                open={openViewAll}
                onClose={() => setOpenViewAll(false)}
                isEditor={isEditor}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                refetch={refetch}
                supplierId={supplier.id}
            />
        </Stack>
    );
};
