export const EmissionsIcon: React.FC = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#F0FDF7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0001 11.9985C14.3445 11.9985 12.9696 13.2055 12.7095 14.7876C12.6604 15.0862 12.4159 15.314 12.1146 15.3419C10.928 15.4518 9.99846 16.4511 9.99846 17.6668C9.99846 18.9564 11.0439 20.0018 12.3335 20.0018H20.3335C21.2549 20.0018 22.0018 19.2549 22.0018 18.3335C22.0018 17.4121 21.2549 16.6651 20.3335 16.6651C20.2366 16.6651 20.1422 16.6733 20.0507 16.6889C19.8375 16.7252 19.6199 16.6555 19.4675 16.5021C19.315 16.3487 19.2466 16.1307 19.2842 15.9177C19.3176 15.7285 19.3351 15.5333 19.3351 15.3335C19.3351 13.4916 17.842 11.9985 16.0001 11.9985ZM11.501 14.0969C12.0431 12.1205 13.8515 10.6685 16.0001 10.6685C18.5765 10.6685 20.6651 12.757 20.6651 15.3335C20.6651 15.3401 20.6651 15.3467 20.6651 15.3533C22.165 15.5183 23.3318 16.7896 23.3318 18.3335C23.3318 19.9894 21.9894 21.3318 20.3335 21.3318H12.3335C10.3093 21.3318 8.66846 19.6909 8.66846 17.6668C8.66846 15.9289 9.87766 14.4741 11.501 14.0969Z"
                fill="#1BBF82"
            />
        </svg>
    );
};
