import { useQuery } from "@apollo/client";
import { ChevronUp, ChevronDown } from "@ignite-analytics/icons";
import { formatDateAsUTC, FormatType } from "@ignite-analytics/locale";
import { Accordion, Chip, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    AssessmentType,
    GetAssessmentsInput,
    InternalAnswers_AnswerFragment,
    InternalAnswers_SectionFragment,
} from "@/gql/graphql";
import { useAlert } from "@/providers";

import { NotResponded } from "../Components/NotResponded";

import { InternalAnswerCard } from "./InternalAnswerCard";

graphql(`
    fragment InternalAnswers_Answer on Answer {
        id
        questionId
        question {
            id
            ...InternalAnswers_Question
        }
        value
        assessmentId
        createdAt
    }
    fragment InternalAnswers_Assessment on Assessment {
        id
        campaignId
        campaign {
            ...GetCampaignOptionsUtil_Campaign
        }
        type
        answers {
            ...InternalAnswers_Answer
        }
        score
        userId
        receiverEmail
        submittedAt
    }
    fragment InternalAnswers_Questionnaire on Questionnaire {
        id
        sections {
            id
            ...InternalAnswers_Section
        }
    }
    fragment InternalAnswers_Section on Section {
        id
        name
        order
        questions {
            id
            ...InternalAnswers_Question
        }
    }
    fragment InternalAnswers_Question on Question {
        id
        title
        description
        type
        order
        options
        maxRating
    }
`);

const getQuestionnaireAndAssessmentsDocument = graphql(`
    query InternalAnswers_GetQuestionnaireAndAssessments(
        $getQuestionnaireInput: GetQuestionnaireInput!
        $getAssessmentsInput: GetAssessmentsInput!
    ) {
        getQuestionnaire(input: $getQuestionnaireInput) {
            questionnaire {
                id
                ...InternalAnswers_Questionnaire
            }
        }
        getAssessments(input: $getAssessmentsInput) {
            assessments {
                id
                ...InternalAnswers_Assessment
            }
        }
    }
`);

export type AnswerWithEmail = InternalAnswers_AnswerFragment & {
    receiverEmail: string;
};

export type InternalAnswersProps = {
    questionnaireId: string;
    campaignId: string;
    supplierId: string;
};

export const InternalAnswers: React.FC<InternalAnswersProps> = ({ questionnaireId, campaignId, supplierId }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const getAssessmentsInput: GetAssessmentsInput =
        campaignId != ""
            ? {
                  supplierId: supplierId,
                  campaignId: campaignId,
                  type: AssessmentType.Internal,
              }
            : {
                  supplierId: supplierId,
                  type: AssessmentType.Internal,
              };

    const { data, loading } = useQuery(getQuestionnaireAndAssessmentsDocument, {
        skip: !questionnaireId || (!campaignId && !supplierId),
        variables: {
            getQuestionnaireInput: {
                id: questionnaireId,
            },
            getAssessmentsInput: getAssessmentsInput,
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get assessment data",
                    description: "Get assessment data fail message",
                }),
                severity: "error",
            });
        },
    });

    if (loading) {
        return (
            <Stack padding={2} boxSizing="border-box">
                <Stack direction="row" alignItems="center" justifyContent="center" height="400px" width="100%">
                    <CircularProgress />
                </Stack>
            </Stack>
        );
    }

    const questionnaire = data?.getQuestionnaire?.questionnaire;
    const assessments =
        campaignId != ""
            ? data?.getAssessments?.assessments
            : data?.getAssessments?.assessments.filter(
                  (assessment) => assessment.campaign.questionnaire.id === questionnaireId
              );

    if (!assessments || !questionnaire) {
        return null;
    }

    const submittedAt = [...assessments.map(({ submittedAt }) => submittedAt)].sort((a, b) => {
        if (!a) return 1;
        if (!b) return -1;
        return new Date(b).getTime() - new Date(a).getTime();
    })[0];
    if (!submittedAt) {
        return <NotResponded />;
    }
    const score = assessments.map(({ score }) => score).reduce((a, b) => a + b);
    const formattedDate = formatDateAsUTC(submittedAt, { formatType: FormatType.onlyDate });
    const sections = [...questionnaire.sections];

    const answerMap: { [key: string]: AnswerWithEmail[] } = {};

    assessments
        .map(({ answers, receiverEmail }) => ({ answers, receiverEmail }))
        .forEach(({ answers, receiverEmail }) => {
            answers.forEach((answer) => {
                const entry: AnswerWithEmail = { ...answer, receiverEmail: receiverEmail ?? "" };
                if (!answerMap[answer.questionId]) {
                    answerMap[answer.questionId] = [entry];
                    return;
                }
                answerMap[answer.questionId].push(entry);
            });
        });

    return (
        <Stack margin={2}>
            <Stack width="100%" direction="row" spacing={2} alignItems="center">
                <Paper variant="outlined" sx={{ width: 333, px: 2 }}>
                    <Stack alignItems="flex-start" my={2} spacing={1}>
                        <Typography variant="textXs">
                            <FormattedMessage
                                defaultMessage="Submission date"
                                description="Submission date header on Assessment tab"
                            />
                        </Typography>
                        <Chip label={formattedDate} />
                    </Stack>
                </Paper>
                <Paper variant="outlined" sx={{ width: 333, px: 2 }}>
                    <Stack alignItems="flex-start" my={2} spacing={1}>
                        <Typography variant="textXs">
                            <FormattedMessage defaultMessage="Score" description="Score header on Assessment tab" />
                        </Typography>
                        <Chip label={score} />
                    </Stack>
                </Paper>
            </Stack>
            <Stack width="100%">
                {sections
                    .sort((a, b) => a.order - b.order)
                    .map((section) => {
                        return <Section key={section.id} section={section} answerMap={answerMap} />;
                    })}
            </Stack>
        </Stack>
    );
};

type SectionProps = {
    section: InternalAnswers_SectionFragment;
    answerMap: {
        [key: string]: AnswerWithEmail[];
    };
};
const Section: React.FC<SectionProps> = ({ section, answerMap }) => {
    const [open, setOpen] = useState<boolean>(section.order === 0);
    const toggleOpen = () => setOpen((prev) => !prev);

    const questions = [...section.questions];
    return (
        <Stack key={section.id} marginY={2}>
            <Accordion expanded={open} sx={{ border: "none", boxShadow: "none" }}>
                <Stack direction="row" alignItems="center">
                    <IconButton
                        size="small"
                        onClick={toggleOpen}
                        sx={{
                            "&:focus": {
                                outline: "none",
                                border: "none",
                                boxShadow: "none",
                            },
                        }}
                    >
                        {open ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                    <Typography variant="textMd" fontWeight={500}>
                        {section.name}
                    </Typography>
                </Stack>
                <Stack spacing={3}>
                    {questions
                        .sort((a, b) => a.order - b.order)
                        .map((question) => {
                            return (
                                <InternalAnswerCard
                                    key={question.id}
                                    answers={answerMap[question.id]}
                                    question={question}
                                />
                            );
                        })}
                </Stack>
            </Accordion>
        </Stack>
    );
};
