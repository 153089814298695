import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Insight as Insight } from "./spendUtils";

type InsightCardProps = {
    insight: Insight;
};

export const InsightCard: React.FC<InsightCardProps> = ({ insight }) => {
    const { formatMessage } = useIntl();

    return (
        <Stack>
            <Box display="inline-flex">
                <Chip color="primary" label={formatMessage({ defaultMessage: "Insight" })} />
            </Box>
            <Typography marginY={2} variant="textSm">
                {insight.description}
            </Typography>
            <Stack spacing={1} direction="row">
                {insight.takeAction && (
                    <Button color="secondary" size="xsmall" onClick={insight.takeAction}>
                        <FormattedMessage defaultMessage="Take action" />
                    </Button>
                )}
                {insight.learnMore && (
                    <Button color="secondary" size="small" onClick={insight.learnMore} style={{ border: "none" }}>
                        <FormattedMessage defaultMessage="Learn more" />
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
