import { Search } from "@ignite-analytics/icons";
import { InputAdornment, TextFieldProps } from "@mui/material";
import { useGridApiContext, useGridRootProps } from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

type GridToolbarSearchFieldProps = {
    slotProps?: { textField?: Partial<Omit<TextFieldProps, "onChange">> };
};

const GridToolbarSearchField = React.forwardRef<HTMLInputElement, GridToolbarSearchFieldProps>(
    function GridToolbarSearchField(props, ref) {
        const apiRef = useGridApiContext();
        const textFieldProps = useMemo(() => props.slotProps?.textField || {}, [props.slotProps?.textField]);
        const rootProps = useGridRootProps();
        const { formatMessage } = useIntl();
        const defaultLabel = formatMessage({
            defaultMessage: "Search in table",
            description: "Search placeholder",
        });

        const debouncedHandleChange = React.useMemo(
            () =>
                debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    apiRef.current?.setQuickFilterValues([value]);
                    rootProps?.slotProps?.toolbar?.search?.onChange?.(value);
                }, 300),
            [apiRef, rootProps?.slotProps?.toolbar?.search]
        );

        return (
            <rootProps.slots.baseTextField
                ref={ref}
                size="medium"
                defaultValue={apiRef.current.state.filter.filterModel.quickFilterValues?.[0]}
                placeholder={defaultLabel}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
                {...textFieldProps}
                {...rootProps.slotProps?.baseTextField}
                onChange={debouncedHandleChange}
            />
        );
    }
);

interface CustomToolbarProps {
    search?: {
        /** Optional callback for handling value changes - MUI state is handled by default */
        onChange?: (value: string) => void;
    };
}

declare module "@mui/x-data-grid-pro" {
    // Disabling this eslint rule as we simply want to extend the module definition with our custom props.
    // The eslint fix breaks the intended behavior.
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
    interface ToolbarPropsOverrides extends CustomToolbarProps {}
}

export { GridToolbarSearchField };
