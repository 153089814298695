export const AssessmentsIcon: React.FC = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#EEF2FF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33594 12C9.33594 10.5281 10.5291 9.33496 12.0009 9.33496H20.0009C21.4728 9.33496 22.6659 10.5281 22.6659 12V20C22.6659 21.4718 21.4728 22.665 20.0009 22.665H12.0009C10.5291 22.665 9.33594 21.4718 9.33594 20V12ZM12.0009 10.665C11.2636 10.665 10.6659 11.2627 10.6659 12V20C10.6659 20.7373 11.2636 21.335 12.0009 21.335H20.0009C20.7382 21.335 21.3359 20.7373 21.3359 20V12C21.3359 11.2627 20.7382 10.665 20.0009 10.665H12.0009ZM19.117 13.5098C19.3877 13.7579 19.406 14.1786 19.1578 14.4493L15.4911 18.4493C15.3652 18.5867 15.1873 18.665 15.0009 18.665C14.8145 18.665 14.6367 18.5867 14.5107 18.4493L12.8441 16.6311C12.5959 16.3604 12.6142 15.9397 12.8849 15.6916C13.1556 15.4434 13.5763 15.4617 13.8245 15.7324L15.0009 17.0158L18.1774 13.5506C18.4256 13.2799 18.8462 13.2616 19.117 13.5098Z"
                fill="#6265F0"
            />
        </svg>
    );
};
