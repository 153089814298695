import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Plus } from "@ignite-analytics/icons";
import { Button, Card, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { GridRowParams, GridToolbarContainer } from "@mui/x-data-grid-pro";
import * as Sentry from "@sentry/react";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { EmissionsIcon } from "@/assets/EmissionsIcon";
import { DataGrid } from "@/components/Common/DataGrid/DataGrid";
import { GridToolbarSearchField } from "@/components/Common/DataGrid/Toolbar/GridToolbarSearchField";
import { GridToolbarTotalItemsContainer } from "@/components/Common/DataGrid/Toolbar/GridToolbarTotalItemsContainer";
import { NoData } from "@/components/Common/NoData";
import { camelCaseToEnglish } from "@/components/Common/utils";
import { graphql } from "@/gql";
import { ElectricityBasis, GetSupplierActivitiesAndUsersDocument, UserFragment } from "@/gql/graphql";
import { useCarbonPermission } from "@/lib/permissions";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { columns } from "./EmissionsTableDefinitions";

interface TranslatedActivity {
    id: string;
    activity: string;
    category: string;
    estimatedEmissions: number;
    year: number;
    region: string | null | undefined;
    user: string;
}

graphql(`
    fragment User on RoleUser {
        id
        firstName
        lastName
    }

    query GetSupplierActivitiesAndUsers($input: GetSupplierInput!, $activitiesInput: SupplierActivitiesInput!) {
        getSupplier(input: $input) {
            supplier {
                activities(activitiesInput: $activitiesInput) {
                    skip
                    limit
                    total
                    activities {
                        id
                        supplierId
                        businessUnitId
                        spendCategoryId
                        category
                        activityL1
                        activityL2
                        emissions
                        year
                        region
                        createdBy
                    }
                }
            }
        }
        getUsers(input: {}) {
            result {
                ...User
            }
        }
    }
`);

interface EmissionsTableProps {
    supplierId: string;
}

export const EmissionsTable: React.FC<EmissionsTableProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();
    const navigate = useOutgoingNavigate();
    const { alertUser } = useAlert();
    const hasCarbonWritePermission = useCarbonPermission({ object: "general", relation: "write" });

    /**
     * Feature Toggle: [Carbon Developers](https://app.launchdarkly.com/projects/default/flags/carbon-developers/targeting?env=production&env=test&selected-env=test)
     *
     * Only true for developers working for Carbon, intended to be used for debugging related to Carbon.
     * Added to debug an issue where navigating to Add activity in Carbon behaved unexpectedly.
     *
     * This is a temporary flag, and it, and the related code, should be removed once the issue is resolved.
     * If you're reading this after October 1. 2024, and the flag is still present, please remove it and the feature
     * toggled code.
     *
     * Thanks 🫶
     */
    const isCarbonDev = useFeatureToggle("carbon-developers");

    const handleRowClick = (params: GridRowParams) => {
        track("Supplier Profile: Navigated Emissions Activity");
        window.open(`https://app.igniteprocurement.com/carbon/overview?activityId=${params.row.id}`, "_blank");
        // navigate(`/carbon/overview?${params.row.id}`); TODO: wait until carbon activity dialog can render as overlaid microapp
    };

    const reportingYear = (new Date().getFullYear() - 1).toString();

    const activityUrl =
        `/carbon/data-input/add-activity/form/purchasedGoodsAndServices` + // TODO: Detect when NOT this (power/utility suppliers)
        `?supplierId=${supplierId}` +
        `&formType=activityL1` +
        `&activityYear=${reportingYear}`;

    const handleAddActivity = () => {
        track("Supplier Profile: Navigated Add Emissions Activity");
        navigate(activityUrl);
    };

    const { data, loading } = useQuery(GetSupplierActivitiesAndUsersDocument, {
        errorPolicy: "all",
        variables: {
            input: {
                id: supplierId,
            },
            activitiesInput: {
                filter: {
                    businessUnitIds: [],
                    spendCategoryIds: [],
                },
                paginationInput: {
                    skip: 0,
                    limit: 100,
                },
                electricityBasis: ElectricityBasis.MarketBased,
            },
        },
        onError: (error) => {
            alertUser({
                value: formatMessage({ defaultMessage: `Could not get activity data` }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile", message: "Failed to get activity data" },
            });
        },
    });
    const loadedActivities: TranslatedActivity[] = useMemo(() => {
        return (
            (data?.getSupplier?.supplier?.activities?.activities?.map((activity) => {
                const actL1 = camelCaseToEnglish(activity.activityL1);
                const actL2 = camelCaseToEnglish(activity.activityL2);
                const activityLabel = actL1 === "Default" || actL1 === "" ? "-" : `${actL1} - ${actL2}`;

                const user = data.getUsers.result.find((user: UserFragment) => user.id === activity.createdBy);
                const userName = user
                    ? `${user.firstName} ${user.lastName}`
                    : formatMessage({
                          defaultMessage: "Unknown user",
                          description: "Unknown user label in emissions table",
                      });

                return {
                    id: activity.id,
                    activity: activityLabel,
                    category: camelCaseToEnglish(activity.category),
                    estimatedEmissions: activity.emissions,
                    year: activity.year,
                    region: activity.region,
                    user: userName,
                };
            }) as TranslatedActivity[]) || []
        );
    }, [data?.getSupplier?.supplier?.activities.activities, data?.getUsers.result, formatMessage]);

    const ActivitiesHeader = () => (
        <Stack spacing={2} direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
                <EmissionsIcon />
                <Typography variant="textXl" fontWeight={500}>
                    <FormattedMessage defaultMessage="Activity log" description="Activity log table header" />
                </Typography>
            </Stack>
            {isCarbonDev && (
                <>
                    <Link to={activityUrl}>React router link</Link>
                    <a href={activityUrl}>Anchor link</a>
                </>
            )}
            {hasCarbonWritePermission && (
                <Stack direction="row" spacing={1}>
                    <Button
                        startIcon={<Plus />}
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={handleAddActivity}
                    >
                        <FormattedMessage defaultMessage="Add activity" />
                    </Button>
                </Stack>
            )}
        </Stack>
    );

    if (!loadedActivities || loadedActivities.length == 0 || loading) {
        return (
            <Stack spacing={2}>
                <ActivitiesHeader />
                {loading ? (
                    <Skeleton height={376} />
                ) : (
                    <Card>
                        <NoData
                            height="450px"
                            message={formatMessage({ defaultMessage: "No activities added for this supplier yet." })}
                        />
                    </Card>
                )}
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            <ActivitiesHeader />
            {loadedActivities.length > 0 && (
                <DataGrid
                    rows={loadedActivities}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick
                    onRowClick={handleRowClick}
                    slots={{ toolbar: () => <CustomToolbar /> }}
                    pagination
                />
            )}
        </Stack>
    );
};

const CustomToolbar: React.FC = () => {
    return (
        <GridToolbarContainer>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem sx={{ height: "unset" }} />
                    <GridToolbarSearchField />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};
