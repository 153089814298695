import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { DeleteModal_SupplierFileFragment, AppRoutes_GetSupplierDocument } from "@/gql/graphql";
import { getNameAndContentType } from "@/lib/files";

type Props = {
    file: DeleteModal_SupplierFileFragment;
    open: boolean;
    setOpen: (open: boolean) => void;
    setIsLoading: (open: boolean) => void;
    supplierId: string;
};

const DeleteModal_DeleteFileMutation = graphql(`
    mutation DeleteModal_DeleteFileMutation($input: DeleteSupplierFileInput!) {
        deleteSupplierFile(input: $input) {
            ok
        }
    }
`);

graphql(`
    fragment DeleteModal_SupplierFile on SupplierFile {
        name
        metaJson
    }
`);

export const DeleteModal: React.FC<Props> = ({ file, open, setOpen, setIsLoading, supplierId }) => {
    const theme = useTheme();
    const [deleteFile] = useMutation(DeleteModal_DeleteFileMutation);
    const { originalFileName } = getNameAndContentType(file);
    const handleDeleteFile = (file: DeleteModal_SupplierFileFragment) => {
        setIsLoading(true);
        deleteFile({
            variables: {
                input: {
                    supplierId: supplierId,
                    fileName: file.name,
                },
            },
            onCompleted: () => {
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
            },
            refetchQueries: [AppRoutes_GetSupplierDocument],
        });
    };
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                <Typography variant="h6">
                    <FormattedMessage defaultMessage="Delete File" />
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    <FormattedMessage defaultMessage="Are you sure you want to delete this file?" />
                </Typography>
                <Typography paddingLeft={1} color={theme.palette.error.main}>
                    {originalFileName}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
                    <Button onClick={() => setOpen(false)}>
                        <FormattedMessage defaultMessage="Cancel" />
                    </Button>
                    <Button
                        onClick={async () => {
                            setOpen(false);
                            handleDeleteFile(file);
                            track("Supplier Profile: Deleted Document", { supplierId });
                        }}
                        color="error"
                    >
                        <FormattedMessage defaultMessage="Delete" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
