import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";

import { EmissionFactorDetail } from "./EmissionFactorDetail";

graphql(`
    fragment EmissionFactors_Supplier on Supplier {
        id
        emissionFactors(emissionFactorsInput: { year: $emissionFactorsYear }) {
            emissionFactorCategory
            emissionFactorRegion
            emissionFactorYear
            emissionFactorInDisplayCurrency
            relatedSpend
        }
    }
`);

interface EmissionFactor {
    emissionFactorCategory: string[];
    emissionFactorRegion: string;
    emissionFactorYear: number;
    emissionFactorInDisplayCurrency: number;
    relatedSpend: number;
}

interface EmissionFactorsProps {
    factors: EmissionFactor[] | undefined;
    emissionFactorsYear: number;
    setEmissionFactorsYear: Dispatch<SetStateAction<number>>;
}
interface EmissionFactorsDialogProps {
    open: boolean;
    onClose: () => void;
    factors: EmissionFactor[] | undefined;
}

const EmissionFactorsHeader: React.FC = () => {
    return (
        <Stack mb={2}>
            <Typography variant="textLg" fontWeight={500}>
                <FormattedMessage
                    defaultMessage="Emission factors details for spend"
                    description="Emission factor section header"
                />
            </Typography>
            <Typography variant="textSm" color="text.text-helper">
                <FormattedMessage
                    defaultMessage="Explore the specific data behind each emission factor to ensure accurate calculations."
                    description="Emission factor section explanation"
                />
            </Typography>
        </Stack>
    );
};

const EmissionFactorsFooter: React.FC<{ count: number }> = ({ count }) => {
    return (
        <Stack mt={2} direction="row" spacing={2}>
            <Stack direction="row" spacing={0.5}>
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage
                        defaultMessage="Data source:"
                        description="Data source disclaimer for emission factors"
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Exiobase" />
                </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage
                        defaultMessage="Total emission factors:"
                        description="Emission factor count helper"
                    />
                </Typography>
                <Typography variant="textSm">{count}</Typography>
            </Stack>
        </Stack>
    );
};

const EmissionFactorsDialog: React.FC<EmissionFactorsDialogProps> = ({ open, onClose, factors }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <EmissionFactorsHeader />
                    <IconButton onClick={onClose}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {factors?.map((factor, index) => (
                        <Grid item xs={12} key={index}>
                            <EmissionFactorDetail
                                emissionFactorCategory={factor.emissionFactorCategory}
                                emissionFactorRegion={factor.emissionFactorRegion}
                                emissionFactorYear={factor.emissionFactorYear}
                                emissionFactorInDisplayCurrency={factor.emissionFactorInDisplayCurrency}
                                relatedSpend={factor.relatedSpend}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <EmissionFactorsFooter count={factors?.length ?? 0} />
                    <Button color="secondary" onClick={onClose} size="small">
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export const EmissionFactors: React.FC<EmissionFactorsProps> = ({
    factors,
    emissionFactorsYear,
    setEmissionFactorsYear,
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const currentYear = new Date().getFullYear(); // TODO fix year selections after query params fixed
    const startYear = 2020;
    const years = Array.from(new Array(currentYear - startYear + 1), (_, index) => currentYear - index);
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <EmissionFactorsHeader />
                <Stack direction="row" spacing={2}>
                    <Select
                        value={emissionFactorsYear}
                        onChange={(e) => {
                            track("Supplier Profile: Changed Emissions Factor Year", { year: e.target.value });
                            setEmissionFactorsYear(Number(e.target.value));
                        }}
                        displayEmpty
                        size="small"
                        sx={{ height: 40 }}
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        color="secondary"
                        size="small"
                        disabled={factors && factors.length <= 4}
                        onClick={() => setDialogOpen(true)}
                    >
                        <FormattedMessage defaultMessage="View all" />
                    </Button>
                </Stack>
            </Stack>
            <Stack spacing={2}>
                {factors?.map((factor, index) => (
                    <EmissionFactorDetail
                        key={index}
                        emissionFactorCategory={factor.emissionFactorCategory}
                        emissionFactorRegion={factor.emissionFactorRegion}
                        emissionFactorYear={factor.emissionFactorYear}
                        emissionFactorInDisplayCurrency={factor.emissionFactorInDisplayCurrency}
                        relatedSpend={factor.relatedSpend}
                    />
                ))}
            </Stack>
            <EmissionFactorsFooter count={factors?.length ?? 0} />
            <EmissionFactorsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} factors={factors} />
        </>
    );
};
