import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SpendIcon } from "@/assets/SpendIcon";
import {
    DatePeriodSelection,
    getStaticFromRelative,
    Insight,
    relativeDatePeriodOptions,
    useDatePeriodLabel,
} from "@/components/Common/spendUtils";
import { DateInterval } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

import { CategoryChart, CategoryData } from "./CategoryChart";
import { DepartmentChart, DepartmentData } from "./DepartmentChart";
import { PeriodSelector } from "./PeriodSelector";
import { SpendChartContainer } from "./SpendChartContainer";
import { SpendDevelopmentChart, SpendDevelopmentData } from "./SpendDevelopmentChart";
import { TransactionChart, TransactionData } from "./TransactionChart";
import { useSpendData } from "./useSpendData";
import { QueryStatus } from "./utils";

export type DevelopmentChartData = {
    queryStatus: QueryStatus;
    spendDevelopmentData: SpendDevelopmentData[];
    total: number;
};

export type CategoryChartData = {
    queryStatus: QueryStatus;
    categoryData: CategoryData[];
    categories: string[];
    insight?: Insight;
};

export type TransactionChartData = {
    queryStatus: QueryStatus;
    transactionData: TransactionData[];
};

export type DepartmentChartData = {
    queryStatus: QueryStatus;
    departmentData: DepartmentData[];
};

interface SpendPageProps {
    supplierId: string;
}

export const SpendPage: React.FC<SpendPageProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();
    const currency = useCompanyCurrency();

    const [period, setPeriod] = useState<DatePeriodSelection>({
        relative: relativeDatePeriodOptions.last12months,
        interval: DateInterval.Month,
    });

    const [insights, setInsights] = useState<Insight[]>([]);

    const { developmentData, categoryData, transactionData, departmentData } = useSpendData(
        supplierId,
        period,
        setInsights,
        currency
    );

    // If there is no data for the last 12 months, change the period to last 5 years
    const [no12MonthsData, setNo12MonthsData] = useState(false);
    if (developmentData.queryStatus === "noData" && period.relative?.key === "last12months" && !no12MonthsData) {
        setNo12MonthsData(true);
        setPeriod({
            relative: relativeDatePeriodOptions.last5Years,
            static: getStaticFromRelative(relativeDatePeriodOptions.last5Years),
            interval: DateInterval.Year,
        });
    }

    const periodKey = useDatePeriodLabel(period);

    return (
        <Stack gap={3} width="100%">
            <Stack spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1}>
                    <SpendIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Spend" />
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                    <PeriodSelector
                        datePeriodSelection={period}
                        onDatePeriodChange={(newDatePeriodSelection: DatePeriodSelection) => {
                            track("Supplier Profile: Changed Spend Development Period", {
                                period: newDatePeriodSelection?.relative?.key,
                            });
                            setPeriod(newDatePeriodSelection);
                            setInsights([]);
                            return null;
                        }}
                    />
                </Stack>
            </Stack>

            <SpendChartContainer
                type="development"
                header={formatMessage({ defaultMessage: "Spend development" })}
                queryStatus={developmentData.queryStatus}
                currency={currency}
                total={developmentData.total}
                periodKey={periodKey}
                chart={
                    <SpendDevelopmentChart
                        period={period}
                        currency={currency}
                        periodKey={periodKey}
                        spendDevelopmentData={developmentData.spendDevelopmentData}
                    />
                }
            />
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={12} lg={6}>
                    <SpendChartContainer
                        type="category"
                        header={formatMessage({ defaultMessage: "Category composition" })}
                        queryStatus={categoryData.queryStatus}
                        insight={insights.find((insight) => insight.chart === "category")}
                        periodKey={periodKey}
                        currency={currency}
                        chart={
                            <CategoryChart
                                currency={currency}
                                period={period}
                                categoryData={categoryData.categoryData}
                                categories={categoryData.categories}
                            />
                        }
                    />
                </Grid>
                <Grid item md={12} lg={6} alignItems="stretch">
                    <SpendChartContainer
                        type="transaction"
                        header={formatMessage({ defaultMessage: "Number of transactions and average value" })}
                        queryStatus={transactionData.queryStatus}
                        insight={insights.find((insight) => insight.chart === "transaction")}
                        periodKey={periodKey}
                        currency={currency}
                        chart={
                            <TransactionChart
                                currency={currency}
                                period={period}
                                transactionData={transactionData.transactionData}
                            />
                        }
                    />
                </Grid>
            </Grid>
            <SpendChartContainer
                type="department"
                header={formatMessage({ defaultMessage: "Spend by business unit" })}
                queryStatus={departmentData.queryStatus}
                insight={insights.find((insight) => insight.chart === "department")}
                periodKey={periodKey}
                currency={currency}
                chart={<DepartmentChart currency={currency} departmentData={departmentData.departmentData} />}
            />
        </Stack>
    );
};
