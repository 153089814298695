import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { DatePeriodSelection } from "@/components/Common/spendUtils";
import { DateInterval } from "@/gql/graphql";

import { formatSpend, getLabel, getMonth } from "./utils";

export type SpendDevelopmentData = {
    [x: string]: string | number;
    label: string;
};

type SpendDevelopmentChartProps = {
    period: DatePeriodSelection;
    currency?: string;
    periodKey: string;
    spendDevelopmentData: SpendDevelopmentData[];
};

export const SpendDevelopmentChart: React.FC<SpendDevelopmentChartProps> = ({
    period,
    currency,
    periodKey,
    spendDevelopmentData,
}) => {
    const theme = useTheme();

    const [isBarHovered, setIsBarHovered] = useState(false);
    const handleMouseOver = () => setIsBarHovered(true);
    const handleMouseOut = () => setIsBarHovered(false);

    return (
        <Stack onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={spendDevelopmentData}>
                    <XAxis
                        dataKey="label"
                        tickFormatter={(l) => getLabel(l, period.interval)}
                        axisLine={false}
                        tickLine={false}
                        tick={{
                            fill: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 14,
                        }}
                    />
                    <YAxis
                        axisLine={{ stroke: "transparent" }}
                        tickLine={false}
                        tickFormatter={(value) => formatSpend(value, true)}
                        tick={{
                            fill: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 14,
                        }}
                    />
                    <CartesianGrid strokeDasharray="1 3" />
                    <Bar
                        dataKey={periodKey}
                        fill={theme.palette.secondary.main}
                        opacity={isBarHovered ? 0.6 : 1}
                        activeBar={<Rectangle opacity={1} fill={theme.palette.secondary.main} />}
                    />
                    <Tooltip
                        content={({ payload }) => {
                            const data = payload?.[0];
                            return (
                                <Card sx={{ minWidth: 150, position: "relative" }}>
                                    <Stack margin={1}>
                                        <Typography variant="textSm" marginLeft={0.5}>
                                            {period.interval == DateInterval.Month
                                                ? getMonth(data?.payload.label, "long")
                                                : data?.payload.label}
                                        </Typography>
                                        <Stack direction="row" spacing={1} marginY={0.5}>
                                            <Box
                                                sx={{
                                                    top: 16,
                                                    bottom: 16,
                                                    left: 8,
                                                    width: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: theme.palette.secondary.main,
                                                }}
                                            />
                                            <Stack>
                                                <Typography variant="textSm">
                                                    <FormattedMessage defaultMessage="Spend" />
                                                </Typography>
                                                <Typography variant="textMd">
                                                    {formatSpend(Number(data?.value), true, currency)}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Card>
                            );
                        }}
                        cursor={{ fill: "transparent" }}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
};
