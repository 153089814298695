export const SpendIcon: React.FC = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#EFF8FE" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99996 9.33496C10.3672 9.33496 10.665 9.63269 10.665 9.99996V20C10.665 20.7373 11.2627 21.335 12 21.335H22C22.3672 21.335 22.665 21.6327 22.665 22C22.665 22.3672 22.3672 22.665 22 22.665H12C10.5281 22.665 9.33496 21.4718 9.33496 20V9.99996C9.33496 9.63269 9.63269 9.33496 9.99996 9.33496ZM21.8035 13.5297C22.0632 13.7894 22.0632 14.2105 21.8035 14.4702L18.4702 17.8035C18.2105 18.0632 17.7894 18.0632 17.5297 17.8035L15.3333 15.6071L13.1369 17.8035C12.8772 18.0632 12.4561 18.0632 12.1964 17.8035C11.9367 17.5438 11.9367 17.1228 12.1964 16.8631L14.8631 14.1964C15.1228 13.9367 15.5438 13.9367 15.8035 14.1964L18 16.3928L20.8631 13.5297C21.1228 13.27 21.5438 13.27 21.8035 13.5297Z"
                fill="#069CDF"
            />
        </svg>
    );
};
