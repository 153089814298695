import { useMutation } from "@apollo/client";
import { DotsVertical, Pen, Plus, Trash } from "@ignite-analytics/icons";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import {
    DeleteContactModal_ContactFragment,
    SupplierContacts_ContactFragment,
    SupplierContacts_SupplierFragment,
} from "@/gql/graphql";
import { track } from "@/lib/track";

import { DeleteContactModal } from "./DeleteContactModal";
import { UpsertContactModal } from "./UpsertContactModal";

graphql(`
    fragment SupplierContacts_Contact on Contact {
        id
        firstName
        lastName
        email
        position
        phone {
            countryCode
            number
        }
        ...DeleteContactModal_Contact
    }

    fragment SupplierContacts_Supplier on Supplier {
        id
        contacts {
            ...SupplierContacts_Contact
        }
    }
`);

const deleteContactsMutation = graphql(`
    mutation deleteContacts($input: DeleteContactsInput!) {
        deleteContacts(input: $input) {
            ids
        }
    }
`);

interface SupplierContactsProps {
    supplier: SupplierContacts_SupplierFragment;
    isEditor: boolean;
}

export const SupplierContacts: React.FC<SupplierContactsProps> = ({ supplier, isEditor }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [contact, setContact] = useState<SupplierContacts_ContactFragment | undefined>(undefined);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openUpsertModal, setOpenUpsertModal] = useState(false);
    const [deleteContacts] = useMutation(deleteContactsMutation);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, contact: SupplierContacts_ContactFragment) => {
        setAnchorEl(event.currentTarget);
        setContact(contact);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setContact(undefined);
    };

    const handleCloseUpsertModal = () => {
        setOpenUpsertModal(false);
        setContact(undefined);
    };

    const handleOpenDeleteModal = () => {
        track("Supplier Profile: Opened Delete Contact Modal");
        setOpenDeleteModal(true);
        setAnchorEl(null);
    };

    const handleDelete = (contact: DeleteContactModal_ContactFragment) => {
        deleteContacts({
            variables: {
                input: {
                    ids: [contact.id],
                },
            },
            onCompleted: () => {
                // INFORM USER OF SUCCESS
                setOpenDeleteModal(false);
                setAnchorEl(null);
            },
            onError: () => {
                //INFORM USER OF ERROR
                setOpenDeleteModal(false);
            },
            update(cache, { data }) {
                if (data?.deleteContacts.ids.length) {
                    data.deleteContacts.ids.forEach((id) => {
                        cache.evict({ id: `Contact:${id}` });
                    });
                    cache.gc();
                }
            },
        });
    };

    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Contacts" description="supplier contacts header" />
                    </Typography>
                }
            />
            <CardContent>
                <Stack spacing={2}>
                    {supplier.contacts?.map((c) => (
                        <Stack key={c.id} spacing={0.25}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography
                                    variant="textSm"
                                    fontWeight="medium"
                                >{`${c.firstName} ${c.lastName}`}</Typography>
                                <IconButton onClick={(e) => handleClick(e, c)} size="xsmall" key={`${c.id}-vert-menu`}>
                                    <DotsVertical />
                                </IconButton>
                                <Menu
                                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    open={anchorEl !== null}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                >
                                    <MenuItem
                                        sx={{
                                            "& .MuiSvgIcon-root": { width: "16px", height: "16px" },
                                        }}
                                        onClick={() => {
                                            track("Supplier Profile: Opened Edit Contact Modal");
                                            setAnchorEl(null);
                                            setOpenUpsertModal(true);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Pen fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="textSm">
                                            <FormattedMessage
                                                defaultMessage="Edit contact"
                                                description="edit contact menu button"
                                            />
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        color="error"
                                        sx={{
                                            "& .MuiSvgIcon-root": { width: "16px", height: "16px" },
                                        }}
                                        onClick={handleOpenDeleteModal}
                                    >
                                        <ListItemIcon>
                                            <Trash fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="textSm">
                                            <FormattedMessage
                                                defaultMessage="Delete contact"
                                                description="delete contact menu button"
                                            />
                                        </Typography>
                                    </MenuItem>
                                </Menu>
                            </Stack>
                            {c.position && <Typography variant="textSm">{c.position}</Typography>}
                            {c.email && <Typography variant="textSm">{c.email}</Typography>}
                            {c.phone?.number && (
                                <Typography variant="textSm">{`${c.phone.countryCode !== 0 ? `+${c.phone.countryCode} ` : ""}${c.phone.number}`}</Typography>
                            )}
                        </Stack>
                    ))}
                    <Stack maxWidth="140px">
                        <NoPermissionTooltip
                            hasPermission={isEditor}
                            placement="top"
                            message={
                                <FormattedMessage defaultMessage="Your permissions do not allow adding a contact" />
                            }
                        >
                            <Button
                                onClick={() => {
                                    track("Supplier Profile: Clicked Add Contact");
                                    setOpenUpsertModal(true);
                                }}
                                startIcon={<Plus />}
                                size="small"
                                disabled={!isEditor}
                                color="linkPrimary"
                                sx={{ justifyContent: "flex-start" }}
                            >
                                <FormattedMessage defaultMessage="Add contact" description="add contact button" />
                            </Button>
                        </NoPermissionTooltip>
                    </Stack>
                </Stack>
                {openDeleteModal && contact && (
                    <DeleteContactModal
                        contact={contact}
                        open={openDeleteModal}
                        onClose={handleCloseDeleteModal}
                        onDelete={handleDelete}
                    />
                )}
                {openUpsertModal && (
                    <UpsertContactModal
                        open={openUpsertModal}
                        onClose={handleCloseUpsertModal}
                        contact={contact}
                        supplierId={supplier.id}
                    />
                )}
            </CardContent>
        </Card>
    );
};
