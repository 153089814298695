import { Stack, Typography } from "@mui/material";

import SelectSupplier from "@/components/SelectSupplier";

interface SelectSupplierPageProps {
    refetch: () => void;
}

export const SelectSupplierPage: React.FC<SelectSupplierPageProps> = ({ refetch }) => {
    return (
        <Stack p={3}>
            <Typography variant="h6">Select a supplier:</Typography>
            <SelectSupplier refetch={refetch} />
        </Stack>
    );
};
