import { useQuery } from "@apollo/client";

import { graphql } from "@/gql";
import { ActivityBar_SupplierFragment, UserFragment } from "@/gql/graphql";

import { isValidDate } from "../Pages/Overview/SideColumn/SupplierInfo/utils";

import { NoteActivity } from "./NoteActivity";
import { OnboardingEvaluatedActivity, OnboardingInitiatedActivity } from "./OnboardingActivity";
import { Note, OnboardingEvaluated, OnboardingInitiated } from "./types";
import { aggregateActivityData } from "./utils";

graphql(`
    fragment Note on Note {
        id
        note
        createdBy
        createdAt
        updatedBy
        updatedAt
        supplierId
        reactions {
            emojiCode
            userIDs
        }
    }

    fragment User on RoleUser {
        id
        firstName
        lastName
    }

    fragment Onboarding on Onboarding {
        status
        approverId
        evaluatedAt
        createdAt
    }
    fragment ActivityBar_Supplier on Supplier {
        id
        name
        onboarding {
            ...Onboarding
        }
        notes {
            ...Note
        }
    }
`);

const getUsersDocument = graphql(`
    query Note_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...User
            }
        }
    }
`);

export const Activities: React.FC<{ supplier: ActivityBar_SupplierFragment; isEditor: boolean }> = ({
    supplier,
    isEditor,
}) => {
    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    if (!usersData || !supplier) {
        return;
    }
    const users = usersData?.getUsers?.result;
    return aggregateActivityData(supplier)
        .filter((activity) => isValidDate(activity.timestamp))
        .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
        .map((activity) => {
            switch (activity.type) {
                case "OnboardingInitiated": {
                    const onboardingInitiated = activity.content as OnboardingInitiated;
                    return (
                        <OnboardingInitiatedActivity
                            key={`${activity.type}${supplier.id}`}
                            supplierName={onboardingInitiated.name}
                            createdAt={onboardingInitiated.createdAt}
                        />
                    );
                }
                case "OnboardingEvaluated": {
                    const onboardingEvaluated = activity.content as OnboardingEvaluated;
                    const user = users?.find((user: UserFragment) => user.id === onboardingEvaluated.approverId);
                    return (
                        <OnboardingEvaluatedActivity
                            key={`${activity.type}${supplier.id}`}
                            approvedBy={user?.firstName}
                            onboardingStatus={onboardingEvaluated?.status}
                            evaluatedAt={onboardingEvaluated.evaluatedAt}
                            supplierName={supplier.name}
                        />
                    );
                }
                case "Note": {
                    const noteActivity = activity.content as Note;
                    const user = noteActivity.updatedBy
                        ? users?.find((user: UserFragment) => user.id === noteActivity.updatedBy)
                        : users?.find((user: UserFragment) => user.id === noteActivity.createdBy);
                    return (
                        user && (
                            <NoteActivity
                                note={noteActivity}
                                key={noteActivity.id}
                                createdBy={user}
                                isEditor={isEditor}
                                users={users}
                            />
                        )
                    );
                }
            }
        });
};
