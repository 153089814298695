import { gql, useQuery } from "@apollo/client";
import { Link, Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { AssessmentsIcon } from "@/assets/AssessmentsIcon";
import { NoData } from "@/components/Common/NoData";
import {
    AssessmentsPage_CampaignFragment,
    AssessmentsPage_GetAssessmentsDocument,
    AssessmentsPage_QuestionnaireFragment,
    CampaignType,
} from "@/gql/graphql";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { AnswerRow } from "./Components/AnswerRow";
import { getAssessmentInstances } from "./utils";

gql(`
    fragment AssessmentsPage_Assessment on Assessment {
        id
        submittedAt
        answers {
            id
        }
        campaign {
            ...AssessmentsPage_Campaign
        }
        score
    }
    fragment AssessmentsPage_Campaign on Campaign {
        id
        name
        questionnaire {
            ...AssessmentsPage_Questionnaire
        }
        type
        dueDate
    }
    fragment AssessmentsPage_Questionnaire on Questionnaire {
        id
        name
    }
`);

gql(`
     query AssessmentsPage_GetAssessments($input: GetAssessmentsInput!) {
        getAssessments(input: $input) {
            assessments {
                id
                ...AssessmentsPage_Assessment
            }
        }
    }
`);

interface AssessmentsPageProps {
    supplierId: string;
}

export const AssessmentsPage: React.FC<AssessmentsPageProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const navigate = useOutgoingNavigate();

    const { data, loading } = useQuery(AssessmentsPage_GetAssessmentsDocument, {
        skip: !supplierId,
        variables: {
            input: { supplierId: supplierId },
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get assessment data",
                    description: "Get assessment data fail message",
                }),
                severity: "error",
            });
        },
    });

    const allAssessments = data?.getAssessments?.assessments ?? [];

    // Instances can either be an external campaign, and internal campaign, or an internal questionnaire (all campaigns for that questionnaire)
    const assessmentInstances = getAssessmentInstances(data?.getAssessments?.assessments ?? []);
    const getProps = (instance: AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment) => {
        if ((instance as AssessmentsPage_CampaignFragment).dueDate) {
            const campaign = instance as AssessmentsPage_CampaignFragment;
            if (campaign.type === CampaignType.External) {
                // External campaign
                return {
                    title: campaign.name,
                    dueDate: campaign.dueDate,
                    score: allAssessments.find((a) => a.campaign?.id === campaign.id)?.score,
                    props: {
                        assessmentId:
                            data?.getAssessments.assessments.find((a) => a.campaign?.id === campaign?.id)?.id ?? "",
                        questionnaireId: campaign.questionnaire?.id,
                    },
                };
            } else {
                // Internal campaign
                return {
                    title: campaign.name,
                    dueDate: campaign.dueDate,
                    score: allAssessments
                        .filter((a) => a.campaign?.id === campaign.id)
                        .reduce((acc, a) => acc + a.score, 0),
                    props: {
                        questionnaireId: campaign.questionnaire.id,
                        campaignId: campaign.id,
                        supplierId: supplierId,
                    },
                };
            }
        } else {
            // All campaigns for this internal questionnaire
            const questionnaire = instance as AssessmentsPage_QuestionnaireFragment;
            return {
                title: questionnaire.name,
                score: allAssessments
                    .filter((a) => a.campaign?.questionnaire?.id === questionnaire.id)
                    .reduce((acc, a) => acc + a.score, 0),
                props: {
                    questionnaireId: questionnaire.id,
                    campaignId: "",
                    supplierId: supplierId,
                },
            };
        }
    };

    const internal = assessmentInstances.filter(
        (instance) => (instance as AssessmentsPage_CampaignFragment).type !== CampaignType.External
    );
    const external = assessmentInstances.filter(
        (instance) => (instance as AssessmentsPage_CampaignFragment).type == CampaignType.External
    );

    return (
        <Stack spacing={3} width="100%">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <AssessmentsIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Assessments" description="Assessments title" />
                    </Typography>
                </Stack>
            </Stack>
            {loading ? (
                <Skeleton variant="rectangular" height={600} />
            ) : assessmentInstances.length === 0 ? (
                <NoData
                    height="100%"
                    title={formatMessage({ defaultMessage: "No assessments found" })}
                    message={formatMessage({
                        defaultMessage: "Start creating assessments from the assessments page",
                    })}
                    buttonText={formatMessage({ defaultMessage: "Go to assessments" })}
                    buttonOnClick={() => {
                        track("Supplier Profile: Navigated Assessments");
                        navigate("/assessments/campaigns");
                    }}
                    linkElement={
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Find more help at" />
                            <Link
                                margin={0.5}
                                underline="hover"
                                color={(theme) => theme.palette.secondary.dark}
                                href="https://help-eu.igniteprocurement.com/en/"
                            >
                                <FormattedMessage defaultMessage="Help Center" />
                            </Link>
                        </Typography>
                    }
                />
            ) : (
                <>
                    {internal?.length > 0 && (
                        <>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Internal" />
                            </Typography>
                            {internal.map((instance) => {
                                const { title, dueDate, score, props } = getProps(instance);
                                return (
                                    <AnswerRow
                                        key={instance.id}
                                        name={title}
                                        dueDate={dueDate}
                                        score={score}
                                        props={{ ...props, supplierId: props.supplierId || "" }}
                                    />
                                );
                            })}
                        </>
                    )}
                    {external?.length > 0 && (
                        <>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="External" />
                            </Typography>
                            {external.map((instance) => {
                                const { title, dueDate, score, props } = getProps(instance);
                                return (
                                    <AnswerRow
                                        key={instance.id}
                                        name={title}
                                        dueDate={dueDate}
                                        score={score}
                                        props={{ ...props, supplierId: props.supplierId || "" }}
                                    />
                                );
                            })}
                        </>
                    )}
                </>
            )}
        </Stack>
    );
};
