import { Stack } from "@mui/material";

import { graphql } from "@/gql";
import { MainColumn_SupplierFragment } from "@/gql/graphql";

import { Contracts } from "./Contracts";
import { Documents } from "./Documents";
import { EmissionDevelopment } from "./Graphs/EmissionDevelopment";
import { SpendDevelopment } from "./Graphs/SpendDevelopment";

graphql(`
    fragment MainColumn_Supplier on Supplier {
        id
        ...Documents_Supplier
        ...Contracts_Supplier
        ...EmissionDevelopment_Supplier
    }
`);

interface MainColumnProps {
    supplier: MainColumn_SupplierFragment;
    isEditor: boolean;
    refetch: () => void;
    loading: boolean;
}

export const MainColumn: React.FC<MainColumnProps> = ({ supplier, isEditor, refetch, loading }) => {
    return (
        <Stack spacing={3}>
            <SpendDevelopment supplierId={supplier.id} />
            <EmissionDevelopment supplier={supplier} loading={loading} />
            <Contracts supplier={supplier} isEditor={isEditor} loading={loading} />
            <Documents supplier={supplier} isEditor={isEditor} refetch={refetch} loading={loading} />
        </Stack>
    );
};
