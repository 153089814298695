import { X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";

import { DocumentsList } from "./DocumentsList";
import { UploadButton } from "./UploadButton";

interface AllDocumentsModalProps {
    documents: (DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment)[];
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    isEditor: boolean;
    supplierId: string;
}

export const AllDocumentsModal: React.FC<AllDocumentsModalProps> = ({
    documents,
    open,
    onClose,
    isLoading,
    setIsLoading,
    isEditor,
    supplierId,
    refetch,
}) => {
    const documentsByYear = documents.reduce(
        (acc, file) => {
            if (!file.createdAt) {
                return acc;
            }
            const year = new Date(file.createdAt).getFullYear().toString();
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(file);
            acc[year].sort((a, b) => {
                const createdAtA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
                const createdAtB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
                return createdAtB - createdAtA;
            });
            return acc;
        },
        {} as Record<string, (DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment)[]>
    );

    const sortedDocuments = Object.entries(documentsByYear).sort(([yearA], [yearB]) => Number(yearB) - Number(yearA));
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                    width: "700px",
                },
            }}
            maxWidth="lg"
        >
            <DialogTitle>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Typography variant="h6">
                    <FormattedMessage defaultMessage="Documents" />
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <Stack spacing={2}>
                    {sortedDocuments.map(([year, files]) => (
                        <Stack key={year} spacing={2}>
                            <Typography variant="textSm">{year}</Typography>
                            <DocumentsList
                                files={files}
                                isLoading={isLoading}
                                supplierId={supplierId}
                                isEditor={isEditor}
                            />
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <UploadButton
                        isEditor={isEditor}
                        supplierId={supplierId}
                        setIsLoading={setIsLoading}
                        refetch={refetch}
                    />
                    <Button color="secondary" onClick={onClose} size="xsmall">
                        <FormattedMessage defaultMessage="Close" description="Close button" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
