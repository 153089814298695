export const GraphEmptyState: React.FC = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="8" width="40" height="40" rx="20" fill="#EFF8FE" />
            <rect x="1" y="1" width="54" height="54" rx="27" stroke="#38BCF7" strokeOpacity="0.16" strokeWidth="2" />
            <rect x="5" y="5" width="46" height="46" rx="23" stroke="#38BCF7" strokeOpacity="0.32" strokeWidth="2" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1667 19.6667C20.786 19.6667 19.6667 20.786 19.6667 22.1667V33.8334C19.6667 35.2141 20.786 36.3334 22.1667 36.3334H33.8333C35.214 36.3334 36.3333 35.2141 36.3333 33.8334V22.1667C36.3333 20.786 35.214 19.6667 33.8333 19.6667H22.1667ZM28.8333 23.8334C28.8333 23.3731 28.4602 23 28 23C27.5398 23 27.1667 23.3731 27.1667 23.8334V32.1667C27.1667 32.6269 27.5398 33 28 33C28.4602 33 28.8333 32.6269 28.8333 32.1667V23.8334ZM32.1667 26.3334C32.1667 25.8731 31.7936 25.5 31.3333 25.5C30.8731 25.5 30.5 25.8731 30.5 26.3334V32.1667C30.5 32.6269 30.8731 33 31.3333 33C31.7936 33 32.1667 32.6269 32.1667 32.1667V26.3334ZM24.6667 28C25.1269 28 25.5 28.3731 25.5 28.8334V32.1667C25.5 32.6269 25.1269 33 24.6667 33C24.2064 33 23.8333 32.6269 23.8333 32.1667V28.8334C23.8333 28.3731 24.2064 28 24.6667 28Z"
                fill="#069CDF"
            />
        </svg>
    );
};
