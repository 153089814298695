import { Chip, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { DocumentListFiles } from ".";

interface DocumentTypeSelectorProps {
    file: DocumentListFiles;
    onChange: (file: DocumentListFiles, e: SelectChangeEvent) => void;
    isEditor: boolean;
}

export const DocumentTypeSelector: React.FC<DocumentTypeSelectorProps> = ({ file, onChange, isEditor }) => {
    const { formatMessage } = useIntl();
    const { doctype } = file.metaJson && JSON.parse(file.metaJson);
    const [typeSelection, setTypeSelection] = React.useState<string>(doctype || "other");

    return (
        <Select
            value={typeSelection}
            onChange={(e) => {
                setTypeSelection(e.target.value);
                onChange(file, e);
            }}
            size="small"
            variant="standard"
            disableUnderline
            sx={{ backgroundColor: "inherit", alignContent: "center" }}
            disabled={!isEditor}
            IconComponent={() => null}
            SelectDisplayProps={{ style: { padding: 0 } }}
        >
            <MenuItem value="" disabled>
                <Typography variant="textSm">
                    {formatMessage({
                        defaultMessage: "Select document type",
                        description: "Select document type",
                    })}
                </Typography>
            </MenuItem>
            <MenuItem value="code-of-conduct">
                <Chip
                    label={
                        <Typography variant="textSm" sx={{ textDecoration: "underline", textUnderlineOffset: 1 }}>
                            {formatMessage({
                                defaultMessage: "Code of Conduct",
                                description: "A code of conduct document (signed)",
                            })}
                        </Typography>
                    }
                    color="primary"
                    size="small"
                />
            </MenuItem>
            <MenuItem value="iso-certification">
                <Chip
                    label={
                        <Typography variant="textSm" sx={{ textDecoration: "underline" }}>
                            {formatMessage({
                                defaultMessage: "ISO Certification",
                                description: "An ISO certification document",
                            })}
                        </Typography>
                    }
                    color="primary"
                    size="small"
                />
            </MenuItem>
            <MenuItem value="other">
                <Chip
                    label={
                        <Typography variant="textSm" sx={{ textDecoration: "underline" }}>
                            {formatMessage({
                                defaultMessage: "Other",
                                description: "Other document type",
                            })}
                        </Typography>
                    }
                    color="primary"
                    size="small"
                    sx={{ textUnderlineOffset: 0 }}
                />
            </MenuItem>
        </Select>
    );
};
