import { Stack, Typography, Card, CardContent, Divider, Skeleton, Link } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { InsightCard } from "@/components/Common/InsightCard";
import { NoData } from "@/components/Common/NoData";
import { Insight } from "@/components/Common/spendUtils";

import { formatSpend, QueryStatus } from "./utils";

type SpendChartContainerProps = {
    type: "development" | "category" | "department" | "transaction";
    header?: string;
    queryStatus: QueryStatus;
    insight?: Insight;
    total?: number;
    periodKey?: string;
    currency?: string;
    chart: React.ReactNode;
};

export const SpendChartContainer: React.FC<SpendChartContainerProps> = ({
    type,
    header,
    queryStatus,
    insight,
    total,
    periodKey,
    currency,
    chart,
}) => {
    const { formatMessage } = useIntl();

    return (
        <Stack spacing={2}>
            <Card sx={{ padding: 3, overflow: "visible" }}>
                {header && <SpendHeader header={header} periodKey={periodKey} currency={currency} />}
                {queryStatus === "loading" ? (
                    <Skeleton height={376} />
                ) : queryStatus === "noData" ? (
                    type == "category" ? (
                        <NoData
                            height="376px"
                            message={formatMessage({ defaultMessage: "Found no categorized spend in this period. " })}
                        />
                    ) : (
                        <NoData
                            height="376px"
                            message={formatMessage({ defaultMessage: "Found no spend during this time period. " })}
                        />
                    )
                ) : queryStatus === "error" ? (
                    <NoData
                        height="376px"
                        message={
                            type == "category"
                                ? formatMessage({ defaultMessage: "It seems categories are not set up correctly." })
                                : type == "department"
                                  ? formatMessage({ defaultMessage: "It seems departments are not set up correctly." })
                                  : formatMessage({ defaultMessage: "An error occurred while fetching data." })
                        }
                        linkElement={
                            (type == "category" || type == "department") && window.Intercom ? (
                                <Typography variant="textSm">
                                    <FormattedMessage defaultMessage="Want  some help setting this up?" />
                                    <Link
                                        margin={0.5}
                                        underline="hover"
                                        color={(theme) => theme.palette.secondary.dark}
                                        onClick={() => {
                                            window.Intercom(
                                                "showNewMessage",
                                                "Hi, I need help with setting up category- or company structures."
                                            );
                                        }}
                                    >
                                        <FormattedMessage defaultMessage="Contact customer success" />
                                    </Link>
                                </Typography>
                            ) : undefined
                        }
                    />
                ) : (
                    <>
                        <CardContent sx={{ paddingX: 0 }}>{chart}</CardContent>
                        {(insight || (total && periodKey)) && <Divider sx={{ my: 2 }} />}
                        {insight && <InsightCard insight={insight} />}
                        {total && periodKey && (
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="textMd" fontWeight={500}>
                                    <FormattedMessage
                                        defaultMessage="Spend {period}"
                                        values={{
                                            period: periodKey.toLowerCase(),
                                        }}
                                    />
                                </Typography>
                                <Typography variant="textMd" fontWeight={700}>
                                    {formatSpend(total, false, currency)}
                                </Typography>
                            </Stack>
                        )}
                    </>
                )}
            </Card>
        </Stack>
    );
};

type SpendHeaderProps = {
    header: string;
    periodKey?: string;
    currency?: string;
};

const SpendHeader: React.FC<SpendHeaderProps> = ({ header, periodKey, currency }) => {
    const { formatMessage } = useIntl();
    const sub = periodKey
        ? `${periodKey} ${currency ? formatMessage({ defaultMessage: "in {currency}" }, { currency }) : ""}`
        : "";
    return (
        <Stack spacing={1}>
            <Typography variant="textXl" fontWeight={500}>
                {header}
            </Typography>
            <Typography variant="textSm" sx={{ opacity: 0.6 }}>
                {sub}
            </Typography>
        </Stack>
    );
};
