export const FinancialsIcon: React.FC = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#EFF8FE" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.33594 12C9.33594 10.5281 10.5291 9.33496 12.0009 9.33496H20.0009C21.4728 9.33496 22.6659 10.5281 22.6659 12V20C22.6659 21.4718 21.4728 22.665 20.0009 22.665H12.0009C10.5291 22.665 9.33594 21.4718 9.33594 20V12ZM12.0009 10.665C11.2636 10.665 10.6659 11.2627 10.6659 12V20C10.6659 20.7373 11.2636 21.335 12.0009 21.335H20.0009C20.7382 21.335 21.3359 20.7373 21.3359 20V12C21.3359 11.2627 20.7382 10.665 20.0009 10.665H12.0009ZM16.0009 12.0016C16.3682 12.0016 16.6659 12.2994 16.6659 12.6666V19.3333C16.6659 19.7006 16.3682 19.9983 16.0009 19.9983C15.6337 19.9983 15.3359 19.7006 15.3359 19.3333V12.6666C15.3359 12.2994 15.6337 12.0016 16.0009 12.0016ZM18.6676 14.0016C19.0349 14.0016 19.3326 14.2994 19.3326 14.6666V19.3333C19.3326 19.7006 19.0349 19.9983 18.6676 19.9983C18.3003 19.9983 18.0026 19.7006 18.0026 19.3333V14.6666C18.0026 14.2994 18.3003 14.0016 18.6676 14.0016ZM13.3343 16.0016C13.7015 16.0016 13.9993 16.2994 13.9993 16.6666V19.3333C13.9993 19.7006 13.7015 19.9983 13.3343 19.9983C12.967 19.9983 12.6693 19.7006 12.6693 19.3333V16.6666C12.6693 16.2994 12.967 16.0016 13.3343 16.0016Z"
                fill="#069CDF"
            />
        </svg>
    );
};
