import { formatDateAsUTC, FormatType } from "@ignite-analytics/locale";
import { Box, Divider, Paper, Rating, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { ExternalAnswers_QuestionFragment, QuestionType } from "@/gql/graphql";

type ExternalAnswerProps = {
    value?: string;
    question: ExternalAnswers_QuestionFragment;
};
export const ExternalAnswerCard: React.FC<ExternalAnswerProps> = ({ value, question }) => {
    const title = `${question.order + 1}. ${question?.title}`;
    return (
        <Box marginY={2}>
            <Paper>
                <Stack padding={3}>
                    <Typography marginTop={2} variant="textMd" fontWeight={500}>
                        {title}
                    </Typography>
                    <Stack marginY={2}>
                        <Typography variant="textSm" color="text.text-helper">
                            {question.description}
                        </Typography>
                        <Divider sx={{ marginTop: 2, marginBottom: 4 }} />
                        <AnswerValue question={question} value={value} />
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    );
};

type AnswerValueProps = {
    value?: string;
    question: ExternalAnswers_QuestionFragment;
};

const AnswerTypography = ({ children }: { children: React.ReactNode | string }) => {
    return (
        <Typography variant="textMd" fontWeight={700}>
            {children}
        </Typography>
    );
};

const AnswerValue = ({ question, value }: AnswerValueProps) => {
    const { formatMessage } = useIntl();
    if (!value || value === "") {
        return (
            <AnswerTypography>
                {formatMessage({
                    defaultMessage: "No answer",
                    description: "No answer placeholder",
                })}
            </AnswerTypography>
        );
    }
    switch (question.type) {
        case QuestionType.Boolean: {
            return (
                <AnswerTypography>
                    {value === "1"
                        ? formatMessage({
                              defaultMessage: "Yes",
                              description: "Yes value",
                          })
                        : formatMessage({
                              defaultMessage: "No",
                              description: "No value",
                          })}
                </AnswerTypography>
            );
        }
        case QuestionType.Rating: {
            return <Rating max={question?.maxRating ?? 5} value={Number(value)} readOnly />;
        }
        case QuestionType.SingleSelect: {
            return <AnswerTypography>{value}</AnswerTypography>;
        }
        case QuestionType.MultiSelect: {
            return <AnswerTypography>{value}</AnswerTypography>;
        }
        case QuestionType.Number: {
            return <AnswerTypography>{value}</AnswerTypography>;
        }
        case QuestionType.Date: {
            return (
                <AnswerTypography>
                    {value ? formatDateAsUTC(value, { formatType: FormatType.onlyDate }) : null}
                </AnswerTypography>
            );
        }
        default: {
            return <AnswerTypography>{value}</AnswerTypography>;
        }
    }
};
