import { Box, Chip, LinearProgress, Stack, styled, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

type HorizontalBarProps = {
    title: string;
    value: number;
    total: number;
};
type BarData = {
    title: string;
    value: number;
    total: number;
};

export const RoundedLinearProgress = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
    height: 8,
    borderRadius: 2,
    backgroundColor: theme.palette.grey[300],
}));

const HorizontalBar = ({ title, value, total }: HorizontalBarProps) => {
    const zeroOffset = 1;
    const percentage = value === 0 ? zeroOffset : (value * 100) / total;
    const valueString = `(${value})`;
    return (
        <Stack>
            <Box sx={{ display: "inline-block" }}>
                <Chip label={title} size="small" />
            </Box>
            <Stack direction="row" alignItems="center" width="100%">
                <Box sx={{ flexGrow: 1 }}>
                    <RoundedLinearProgress color="secondary" variant="determinate" value={percentage} />
                </Box>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {valueString}
                </Typography>
            </Stack>
        </Stack>
    );
};

export const BarChart: React.FC<{ bars: BarData[] }> = ({ bars }) => {
    return (
        <Stack spacing={2}>
            {bars.map(({ title, value, total }) => (
                <HorizontalBar key={title + value} title={title} value={value} total={total} />
            ))}
        </Stack>
    );
};
