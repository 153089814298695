import { track as igniteTrack } from "@ignite-analytics/track";

/**
 * Tracks any event in the application.
 *
 * @param event Event to be tracked
 * @param properties Other relevant properties for the event
 */
export function track(event: string, properties?: Record<string, unknown>) {
    try {
        igniteTrack(event, properties);
    } catch (e) {
        console.error(e);
    }
}
