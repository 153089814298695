import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { DeleteContactModal_ContactFragment } from "@/gql/graphql";

graphql(`
    fragment DeleteContactModal_Contact on Contact {
        id
        firstName
        lastName
        email
    }
`);

interface DeleteContactModalProps {
    contact: DeleteContactModal_ContactFragment;
    open: boolean;
    onClose: () => void;
    onDelete: (contact: DeleteContactModal_ContactFragment) => void;
}

export const DeleteContactModal: React.FC<DeleteContactModalProps> = ({ contact, open, onClose, onDelete }) => {
    const { formatMessage } = useIntl();
    const adressName = contact.firstName
        ? `${contact.email} (${contact?.lastName ? `${contact.firstName} ${contact.lastName}` : contact.firstName})`
        : contact.email;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{formatMessage({ defaultMessage: "Delete contact" })}</DialogTitle>
            <DialogContent>
                <Typography variant="textSm">
                    {formatMessage(
                        {
                            defaultMessage:
                                "Are you sure you want to delete {email}? This contact may be removed from related assessments.",
                        },
                        { email: adressName }
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1}>
                    <Button onClick={onClose} color="secondary" variant="text">
                        {formatMessage({ defaultMessage: "Cancel" })}
                    </Button>
                    <Button onClick={() => onDelete(contact)} color="error">
                        {formatMessage({ defaultMessage: "Delete" })}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
