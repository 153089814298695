import { Avatar, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";

import { formatNumber } from "@/components/Common/utils";

const getAvatarInitials = (name: string) => {
    const names = name.split(" ");
    if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    }
    return (names[0].charAt(0) + names[1].charAt(0)).toUpperCase();
};

export const columns: GridColDef[] = [
    {
        field: "category",
        headerName: "Category",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "activity",
        headerName: "Activity",
        flex: 1,
        renderCell: (params) => (
            <Typography variant="textSm" noWrap>
                {params.value}
            </Typography>
        ),
    },
    {
        field: "estimatedEmissions",
        headerName: "Emissions",
        flex: 1,
        renderCell: (params) => (
            <Typography variant="textSm" noWrap>{`${formatNumber(params.value, 2)} tCO₂e`}</Typography>
        ),
    },
    {
        field: "year",
        headerName: "Year",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "region",
        headerName: "Region",
        flex: 1,
        renderCell: (params) => <Typography variant="textSm">{params.value}</Typography>,
    },
    {
        field: "user",
        headerName: "User",
        flex: 1,
        renderCell: (params) => (
            <Stack direction="row" alignItems="center" gap={1} mt={1.5}>
                <Avatar
                    sx={{
                        width: 25,
                        height: 25,
                        fontSize: "inherit",
                    }}
                >
                    {getAvatarInitials(params.value)}
                </Avatar>
                <Typography variant="textSm">{params.value}</Typography>
            </Stack>
        ),
    },
];
