import { graphql } from "@/gql";
import {
    AssessmentsPage_AssessmentFragment,
    AssessmentsPage_CampaignFragment,
    AssessmentsPage_QuestionnaireFragment,
    CampaignType,
} from "@/gql/graphql";

graphql(`
    fragment GetCampaignOptionsUtil_Questionnaire on Questionnaire {
        id
        name
    }
    fragment GetCampaignOptionsUtil_Campaign on Campaign {
        id
        name
        type
        questionnaire {
            id
            ...GetCampaignOptionsUtil_Questionnaire
        }
    }
`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T extends Record<string, any>>(items: T[], property: keyof T): Record<string, T[]> => {
    return items.reduce(
        (acc, item) => {
            const key = item[property];
            if (!key) {
                console.warn(`Property ${String(property)} does not exist on item`, item);
                return acc;
            }
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        },
        {} as Record<string, T[]>
    );
};

type UniqueByProperty<T> = (items: T[], property: keyof T) => T[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uniqueBy: UniqueByProperty<any> = (items, property) => {
    const seen = new Set();
    return items.filter((item) => {
        const key = item[property];
        if (seen.has(key)) {
            return false;
        }
        seen.add(key);
        return true;
    });
};

const sortByMostRecentSubmittedAt = (a: AssessmentsPage_AssessmentFragment, b: AssessmentsPage_AssessmentFragment) => {
    if (!a?.submittedAt) return 1;
    if (!b?.submittedAt) return -1;
    return new Date(b.submittedAt).getTime() - new Date(a.submittedAt).getTime();
};

export const getAssessmentInstances = (
    assessments: AssessmentsPage_AssessmentFragment[]
): (AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment)[] => {
    const campaigns: AssessmentsPage_CampaignFragment[] = uniqueBy(
        [...assessments].sort(sortByMostRecentSubmittedAt).map(({ campaign }) => campaign),
        "id"
    ).sort((a, b) => a.questionnaire.name.localeCompare(b.questionnaire.name));

    const options: (AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment)[] = [];
    campaigns.forEach((campaign) => {
        if (
            campaign.type === CampaignType.Internal &&
            !options.some(
                (option: AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment) =>
                    option.id === campaign.questionnaire.id
            )
        ) {
            options.push(campaign.questionnaire);
        }
        if (
            !options.some(
                (option: AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment) =>
                    option.id === campaign.id
            )
        ) {
            options.push(campaign);
        }
    });

    return options;
};
