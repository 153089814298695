import { List, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { track } from "@/lib/track";

import useIsSmallScreen from "../hooks";

export const SideNavigation: React.FC<{ position: number }> = ({ position }) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const { id } = useParams<{ id: string }>();
    const currentPath = window.location.pathname;

    const items = [
        { path: "overview", message: formatMessage({ defaultMessage: "Overview" }) },
        { path: "spend", message: formatMessage({ defaultMessage: "Spend" }) },
        { path: "emissions", message: formatMessage({ defaultMessage: "Emissions" }) },
        { path: "financials", message: formatMessage({ defaultMessage: "Financials" }) },
        { path: "assessments", message: formatMessage({ defaultMessage: "Assessments" }) },
    ];

    const selectedItem = items.find((item) => currentPath.includes(item.path));

    const isSmallScreen = useIsSmallScreen();

    return (
        <Stack
            maxHeight="calc(100vh - (64px + 88px + 16px + 24px + 24px + 48px))"
            width={isSmallScreen ? "215px" : "245px"}
            minWidth={isSmallScreen ? "215px" : "245px"}
            sx={{
                background: (theme) => theme.palette.background.backgroundSecondary,
                borderRadius: 1,
                position: "sticky",
                top: position,
                marginBottom: 3,
            }}
        >
            <List sx={{ padding: 2, gap: 1.5 }}>
                {items.map((item) => (
                    <ListItem key={item.path} sx={{ paddingX: 0 }}>
                        <ListItemButton
                            onClick={() => {
                                track("Supplier Profile: Clicked Side Navigation", {
                                    page: item.path,
                                });
                                navigate(`supplier-profile/${id}/${item.path}`, { replace: true });
                            }}
                            selected={selectedItem?.path === item.path}
                            disabled={!id}
                            sx={{
                                borderRadius: 1,
                                paddingX: 1.25,
                                paddingY: 0.75,
                                backgroundColor: (theme) =>
                                    selectedItem?.path === item.path
                                        ? `${theme.palette.background.paper} !important`
                                        : "inherit",
                            }}
                        >
                            <Typography variant="textSm" fontWeight={500}>
                                {item.message}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Stack>
    );
};
