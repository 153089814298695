import dayjs from "dayjs";
import { useIntl } from "react-intl";

import { ContractItem_ContractFragment } from "@/gql/graphql";

export function useGetExpirationOrRenewMessage(contract: ContractItem_ContractFragment) {
    const { formatMessage } = useIntl();
    const now = dayjs();

    let renewDiff = undefined;
    let renewUnit = "";
    let renewUnitPlural = "";
    if (contract.renewalDate) {
        const renewDate = dayjs(contract.renewalDate);
        renewDiff = renewDate.diff(now, "month");
        renewUnit = formatMessage({ defaultMessage: "month", description: "month" });
        renewUnitPlural = formatMessage({ defaultMessage: "months", description: "months" });
        if (renewDiff === 0) {
            renewDiff = renewDate.diff(now, "day");
            renewUnit = formatMessage({ defaultMessage: "day", description: "day" });
            renewUnitPlural = formatMessage({ defaultMessage: "days", description: "days" });
        }
    }

    const expireDate = dayjs(contract.endDate);
    let diff = expireDate.diff(now, "month");
    let unit = formatMessage({ defaultMessage: "month", description: "month" });
    let unitPlural = formatMessage({ defaultMessage: "months", description: "months" });

    if (diff === 0) {
        diff = expireDate.diff(now, "day");
        unit = formatMessage({ defaultMessage: "day", description: "day" });
        unitPlural = formatMessage({ defaultMessage: "days", description: "days" });
    }

    return { diff, unit, unitPlural, renewDiff, renewUnit, renewUnitPlural };
}
