import { Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FormatDateOptions, FormatNumberOptions, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { InfoRow_SupplierCustomFieldFragment, OnboardingStatus, SupplierCustomFieldType } from "@/gql/graphql";

import { getLabel } from "./NaceField/helpers";
import { NACEOptions } from "./NaceField/NACEOptions";

graphql(`
    fragment InfoRow_SupplierCustomField on SupplierCustomField {
        name
        dataJson
        fieldType
    }
`);

interface InfoRowProps {
    name: string;
    value: string | string[] | number; // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding";
    customField?: InfoRow_SupplierCustomFieldFragment;
}

const classificationChipColors = ["#ffd4e0", "#ffe0cc", "#ffeab6", "#c1f5f0", "#d1e2ff"];

export interface ClassificationCustomField extends InfoRow_SupplierCustomFieldFragment {
    valueById: Map<string, string>;
}

export const InfoRow: React.FC<InfoRowProps> = ({ name, value, type }) => {
    const { formatDate, formatNumber } = useIntl();

    return (
        <React.Fragment>
            <Grid item xs={6} alignItems="center" textOverflow="ellipsis" overflow="clip">
                <Typography variant="textSm" fontWeight={600}>
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={6} sx={{ overflowX: "hidden", alignItems: "center" }}>
                {formatValue(value, type, formatDate, formatNumber)}
            </Grid>
        </React.Fragment>
    );
};

function formatValue(
    value: string | string[] | number, // as dataJson is any when marshalled
    type: SupplierCustomFieldType | "onboarding",
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string,
    numberFormatter: (value: Parameters<Intl.NumberFormat["format"]>[0], opts?: FormatNumberOptions) => string
): React.ReactNode {
    if (value === "" || value == null || value === undefined) {
        return <React.Fragment />;
    }

    if (type === SupplierCustomFieldType.Risk) {
        switch (value) {
            case "low":
                return <Chip label="Low" color="success" size="small" />;
            case "medium":
                return <Chip label="Medium" color="warning" size="small" />;
            case "high":
                return <Chip label="High" color="error" size="small" />;
        }
    }
    if (typeof value === "string") {
        switch (type) {
            case "onboarding": {
                return getOnboardingChip(value as OnboardingStatus);
            }
            case SupplierCustomFieldType.Date: {
                return <Typography variant="textSm">{formatDate(value, dateFormatter)}</Typography>;
            }
            case SupplierCustomFieldType.Nace: {
                const option = NACEOptions.find((opt) => opt.nace === value.slice(0, 5)) ?? null;
                return <Typography variant="textSm">{getLabel(option)}</Typography>;
            }
        }
    }

    if (Array.isArray(value) && type === SupplierCustomFieldType.Classification) {
        return (
            <Stack direction="row" gap={1} flexWrap="wrap">
                {value.map((v: string, i: number) => {
                    return (
                        <Chip
                            size="small"
                            sx={{ backgroundColor: classificationChipColors[i % value.length] }}
                            key={`${v}_${i}`}
                            label={v}
                        />
                    );
                })}
            </Stack>
        );
    }

    if (typeof value === "number") {
        switch (type) {
            case SupplierCustomFieldType.Number: {
                return <Typography variant="textSm">{numberFormatter(value, { maximumFractionDigits: 2 })}</Typography>;
            }
            case SupplierCustomFieldType.MonetaryAmount: {
                return <Typography variant="textSm">{numberFormatter(value, { maximumFractionDigits: 2 })}</Typography>;
            }
            case SupplierCustomFieldType.Aggregation:
            case SupplierCustomFieldType.Spend:
                return <Typography variant="textSm">{numberFormatter(value, { maximumFractionDigits: 2 })}</Typography>;
        }
    }

    return <Typography variant="textSm">{value}</Typography>;
}

function getOnboardingChip(status: OnboardingStatus | undefined) {
    switch (status) {
        case OnboardingStatus.Approved:
            return <Chip color="success" label="Approved" size="small" />;
        case OnboardingStatus.InOnboarding:
            return <Chip color="warning" label="In onboarding" size="small" />;
        case OnboardingStatus.Rejected:
            return <Chip color="error" label="Rejected" size="small" />;
        default:
            return <></>;
    }
}

// TODO: Have to figure out "empty state"/auto approved of onboarding
function formatDate(
    dateStr: string | null | undefined,
    dateFormatter: (value: Parameters<Intl.DateTimeFormat["format"]>[0] | string, opts?: FormatDateOptions) => string
): React.ReactNode | "" {
    // Check if the input is null or undefined
    if (dateStr === null || dateStr === undefined) {
        return "";
    }

    // Parse the date string
    const date = new Date(dateStr);

    // Create a date object for "0001-01-01 00:00:00 +0000 UTC"
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));

    // Check if the parsed date is invalid
    if (isNaN(date.getTime())) {
        return "";
    }

    // Compare the provided date with the invalid date
    if (date.getTime() === invalidDate.getTime()) {
        return "";
    }

    return dateFormatter(date);
}

export function isValidDate(dateString: string) {
    const date = new Date(dateString ?? "");
    if (!date || isNaN(date.getTime())) {
        return false;
    }
    const invalidDate = new Date(Date.UTC(2001, 0, 1, 0, 0, 0));
    return date.getTime() !== invalidDate.getTime();
}
