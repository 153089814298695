import { Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ContractItem_ContractFragment } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { useGetExpirationOrRenewMessage } from "./hooks";

graphql(`
    fragment ContractItem_Contract on Contract {
        id
        title
        startDate
        endDate
        renewalDate
    }
`);

interface ContractItemProps {
    contract: ContractItem_ContractFragment;
}

export const ContractItem: React.FC<ContractItemProps> = ({ contract }) => {
    const { formatDate, formatMessage } = useIntl();
    const navigate = useOutgoingNavigate();

    const handleRedirect = () => {
        track("Supplier Profile: Navigated to Contracts", { contractId: contract.id });
        navigate(`/contracts/detail/${contract.id}`);
    };

    const { diff, unit, unitPlural, renewDiff, renewUnit, renewUnitPlural } = useGetExpirationOrRenewMessage(contract);

    const renderChip = () => {
        if (contract.renewalDate !== undefined) {
            if (renewDiff && renewDiff < 12) {
                return (
                    <Chip
                        label={formatMessage(
                            {
                                defaultMessage: `Renewal in {renewDiff} {renewDiff, plural, one {{ renewUnit }} other {{ renewUnitPlural }}}`,
                                description: "Expires in {renewDiff} {renewUnit}",
                            },
                            { renewDiff, renewUnit, renewUnitPlural }
                        )}
                        color="warning"
                        size="small"
                    />
                );
            }

            if (diff <= 0) {
                return (
                    <Chip
                        label={formatMessage({ defaultMessage: "Expired", description: "expired tag" })}
                        color="error"
                        size="small"
                    />
                );
            } else if (diff < 12) {
                return (
                    <Chip
                        label={formatMessage(
                            {
                                defaultMessage: `Expires in {diff} {diff, plural, one {{ unit }} other {{ unitPlural }}}`,
                                description: "Expires in {diff} {unit}",
                            },
                            { diff, unit, unitPlural }
                        )}
                        color="warning"
                        size="small"
                    />
                );
            } else {
                return <></>;
            }
        }
    };

    return (
        <Paper key={contract.id} sx={{ padding: 2 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack spacing={1}>
                    <Typography variant="textSm" fontWeight={500}>
                        {contract.title}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {contract.startDate && (
                            <Typography variant="textXs">
                                {formatMessage(
                                    { defaultMessage: "Start date: {startDate}", description: "Start date label" },
                                    { startDate: formatDate(contract.startDate) }
                                )}
                            </Typography>
                        )}
                        {contract.endDate && (
                            <Typography variant="textXs">
                                {formatMessage(
                                    { defaultMessage: "End date: {endDate}", description: "End date label" },
                                    {
                                        endDate: formatDate(contract.endDate),
                                    }
                                )}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {renderChip()}
                    <Button color="secondary" size="2xsmall" onClick={handleRedirect}>
                        <FormattedMessage defaultMessage="View" description="Edit contract button" />
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    );
};
