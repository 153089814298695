import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";

import {
    DatePeriodSelection,
    Insight,
    parseCategoryData,
    toDatePeriodInput,
    useDatePeriodLabel,
} from "@/components/Common/spendUtils";
import { graphql } from "@/gql";
import { ProcurementEntityType, PredefinedMetric } from "@/gql/graphql";

import { DepartmentData } from "./DepartmentChart";
import { formatSpend } from "./utils";

import { DevelopmentChartData, CategoryChartData, TransactionChartData, DepartmentChartData } from ".";

export const getSpendDevelopment = graphql(`
    query GetSpendDevelopment($input: GetSpendDevelopmentInput!) {
        getSpendDevelopment(input: $input) {
            labels
            thisPeriod
            previousPeriod
        }
    }
`);

export const getMetricDevelopment = graphql(`
    query GetMetricDevelopment($input: GetMetricDevelopmentQueryInput!) {
        getMetricDevelopmentQuery(input: $input) {
            intervalResults {
                intervalStart
                intervalLabel
                results {
                    entityId
                    label
                    metrics {
                        metric
                        value
                    }
                }
            }
        }
    }
`);

const getDepartmentData = graphql(`
    query DepartmentChart_GetProcurementEntityChart($input: GetProcurementEntityQueryInput!) {
        getProcurementEntityQuery(input: $input) {
            entities {
                label
                metrics {
                    metric
                    value
                }
            }
        }
    }
`);

export function useSpendData(
    supplierId: string,
    period: DatePeriodSelection,
    setInsights: React.Dispatch<React.SetStateAction<Insight[]>>,
    currency?: string
): {
    developmentData: DevelopmentChartData;
    categoryData: CategoryChartData;
    transactionData: TransactionChartData;
    departmentData: DepartmentChartData;
} {
    const { formatMessage } = useIntl();
    const inputPeriod = toDatePeriodInput(period, period.relative?.period == "month");
    const thisPeriodText = useDatePeriodLabel(period);

    //Spend development data + category insight
    const { data: developmentData, loading: developmentloading } = useQuery(getSpendDevelopment, {
        variables: {
            input: {
                selection: {
                    period: inputPeriod,
                    filters: {
                        suppliers: [supplierId],
                    },
                },
                interval: period.interval,
            },
        },
        skip: supplierId === "",
        onCompleted: (data) => {
            //Generate insight for the category chart
            const prevTotal = data.getSpendDevelopment.previousPeriod.reduce((acc, curr) => acc + curr, 0);
            if (prevTotal === 0) return;
            const percentageChange =
                ((data.getSpendDevelopment.thisPeriod.reduce((acc, curr) => acc + curr, 0) - prevTotal) / prevTotal) *
                100;
            const categoryInsight: Insight = {
                description: formatMessage(
                    {
                        defaultMessage:
                            "Your spend with this supplier this period is {percentageChange} {direction} compared to the previous period.",
                    },
                    {
                        percentageChange: `${Math.abs(percentageChange).toFixed(2)}%`,
                        direction:
                            percentageChange > 0
                                ? formatMessage({ defaultMessage: "higher" })
                                : formatMessage({ defaultMessage: "lower" }),
                    }
                ),
                takeAction: undefined,
                learnMore: undefined,
                chart: "category",
            };
            setInsights((prev) => [...prev, categoryInsight]);
        },
    });
    const developmentChartData: DevelopmentChartData = {
        queryStatus: developmentloading
            ? "loading"
            : !developmentData || developmentData.getSpendDevelopment.thisPeriod.length == 0
              ? "noData"
              : "success",
        spendDevelopmentData:
            developmentData?.getSpendDevelopment.thisPeriod.map((thisPeriod, index) => ({
                label: developmentData?.getSpendDevelopment.labels[index],
                [thisPeriodText]: thisPeriod,
            })) ?? [],
        total: developmentData?.getSpendDevelopment.thisPeriod.reduce((acc, curr) => acc + curr, 0) ?? 0,
    };

    //Category data
    const {
        data: categoryData,
        loading: categoryLoading,
        error: categoryError,
    } = useQuery(getMetricDevelopment, {
        variables: {
            input: {
                filterSelection: {
                    period: inputPeriod,
                    filters: {
                        suppliers: [supplierId],
                    },
                },
                interval: period.interval,
                groupBy: {
                    field: ProcurementEntityType.Category,
                },
                metrics: [PredefinedMetric.Spend, PredefinedMetric.TransactionCount],
            },
        },
    });

    const { categoryData: parsedCategoryData, categories } = parseCategoryData(
        categoryData?.getMetricDevelopmentQuery.intervalResults
    );
    const categoryChartData: CategoryChartData = {
        queryStatus: categoryLoading
            ? "loading"
            : categoryError
              ? "error"
              : !parsedCategoryData || categoryData?.getMetricDevelopmentQuery.intervalResults.length == 0
                ? "noData"
                : "success",
        categories: categories,
        categoryData: parsedCategoryData ?? [],
    };

    //Transaction data
    const { data: transactionData, loading: transactionLoading } = useQuery(getMetricDevelopment, {
        variables: {
            input: {
                filterSelection: {
                    period: inputPeriod,
                    filters: {
                        suppliers: [supplierId],
                    },
                },
                groupBy: {
                    level: 0,
                    field: ProcurementEntityType.Supplier,
                },
                interval: period.interval,
                metrics: [PredefinedMetric.Spend, PredefinedMetric.TransactionCount],
            },
        },
        onCompleted: (data) => {
            //Generate insight for the transaction chart
            let totalTransactions = 0;
            let totalSpend = 0;
            data.getMetricDevelopmentQuery.intervalResults.forEach((period) => {
                period.results.forEach(({ metrics }) => {
                    totalTransactions +=
                        metrics.find((m) => m.metric === PredefinedMetric.TransactionCount)?.value ?? 0;
                    totalSpend += metrics.find((m) => m.metric === PredefinedMetric.Spend)?.value ?? 0;
                });
            });
            if (!totalTransactions || !totalSpend) {
                return;
            }
            const avgValue = totalSpend ? totalSpend / totalTransactions : 0;

            const transactionInsight: Insight = {
                description: formatMessage(
                    {
                        defaultMessage: `In the selected period, the average transaction value with this supplier was {spend}.`,
                    },
                    {
                        spend: formatSpend(avgValue, true, currency),
                    }
                ),
                takeAction: undefined,
                learnMore: undefined,
                chart: "transaction",
            };
            setInsights((prev) => [...prev, transactionInsight]);
        },
    });

    const transactionChartData: TransactionChartData = {
        queryStatus: transactionLoading
            ? "loading"
            : !transactionData || transactionData.getMetricDevelopmentQuery.intervalResults.length == 0
              ? "noData"
              : "success",
        transactionData:
            transactionData?.getMetricDevelopmentQuery.intervalResults.map((result) => {
                const periodData = {
                    period: result.intervalLabel,
                    transactions: 0,
                    avgValue: 0,
                };
                result.results.forEach(({ metrics }) => {
                    const nbrTransaction =
                        metrics.find((m) => m.metric === PredefinedMetric.TransactionCount)?.value ?? 1;
                    periodData.transactions = nbrTransaction;
                    periodData.avgValue =
                        (metrics.find((m) => m.metric === PredefinedMetric.Spend)?.value ?? 0) / nbrTransaction;
                });
                return periodData;
            }) ?? [],
    };

    //Department data
    const {
        data: departmentData,
        loading: departmentLoading,
        error: departmentError,
    } = useQuery(getDepartmentData, {
        variables: {
            input: {
                field: ProcurementEntityType.Department,
                filterSelection: {
                    period: inputPeriod,
                    filters: {
                        suppliers: [supplierId],
                    },
                },
                metrics: [PredefinedMetric.Spend],
            },
        },
    });

    useQuery(getDepartmentData, {
        skip: departmentLoading,
        variables: {
            input: {
                field: ProcurementEntityType.Department,
                filterSelection: {
                    period: inputPeriod,
                },
                metrics: [PredefinedMetric.Spend],
            },
        },
        onCompleted: (d) => {
            // Generate insight for the department chart
            const topEntity = departmentData?.getProcurementEntityQuery.entities[0];
            const topDepartment = topEntity?.label;
            const supplierDepartmetnSpend =
                topEntity?.metrics.find((metric) => metric.metric === PredefinedMetric.Spend)?.value ?? 0;

            const topDepartmentSpend =
                d?.getProcurementEntityQuery.entities
                    .find((entity) => entity.label === topDepartment)
                    ?.metrics.find((metric) => metric.metric === PredefinedMetric.Spend)?.value ?? 0;
            const percentage = (supplierDepartmetnSpend / topDepartmentSpend) * 100;

            const departmentInsight: Insight = {
                description: formatMessage(
                    {
                        defaultMessage:
                            "This supplier makes up {percentage} of the spend in the {department} business unit this period.",
                    },
                    {
                        percentage: `${percentage.toFixed(2)}%`,
                        department: topDepartment,
                    }
                ),
                takeAction: undefined,
                learnMore: undefined,
                chart: "department",
            };
            setInsights((prev) => [...prev, departmentInsight]);
        },
    });
    const departments =
        departmentData?.getProcurementEntityQuery.entities?.map((entity) => {
            const departmentData: DepartmentData = {
                department: entity.label,
                spend: entity.metrics.find((metric) => metric.metric === PredefinedMetric.Spend)?.value ?? 0,
            };
            return departmentData;
        }) ?? [];
    const departmentChartData: DepartmentChartData = {
        queryStatus: departmentLoading
            ? "loading"
            : departmentError
              ? "error"
              : !departmentData || departmentData.getProcurementEntityQuery.entities.length == 0
                ? "noData"
                : "success",
        departmentData: departments,
    };

    return {
        developmentData: developmentChartData,
        categoryData: categoryChartData,
        transactionData: transactionChartData,
        departmentData: departmentChartData,
    };
}
