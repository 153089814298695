import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ElectricityBasis } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { EmissionsDevelopmentCard } from "./EmissionsDevelopmentCard";
import { EmissionsTable } from "./EmissionsTable";

// Will do a refactor where we move fetching of supplier data to the parent component and pass it down as props
const getSupplierQuery = graphql(`
    query EmissionsPage_GetSupplier(
        $input: GetSupplierInput!
        $emissionFactorsYear: Int!
        $electricityBasis: ElectricityBasis!
    ) {
        getSupplier(input: $input) {
            supplier {
                id
                ...EmissionsDevelopmentChart_Supplier
                ...EmissionFactors_Supplier
            }
        }
    }
`);

interface EmissionsPageProps {
    supplierId: string;
}

export const EmissionsPage: React.FC<EmissionsPageProps> = ({ supplierId }) => {
    const [emissionFactorsYear, setEmissionFactorsYear] = useState<number>(2023);
    const [electricityBasis, setElectricityBasis] = useState<ElectricityBasis>(ElectricityBasis.MarketBased);
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const { data: supplierData, loading: loadingSupplier } = useQuery(getSupplierQuery, {
        errorPolicy: "all",
        variables: {
            input: {
                id: supplierId,
            },
            emissionFactorsYear: emissionFactorsYear,
            electricityBasis: electricityBasis,
        },
        skip: supplierId === "",
        onError: (error) => {
            alertUser({
                value: formatMessage({ defaultMessage: `Could not get emissions data` }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile", message: "Failed to get emissions supplier" },
            });
        },
    });

    return (
        <Stack gap={3} width="100%">
            <EmissionsDevelopmentCard
                supplier={supplierData?.getSupplier.supplier}
                loading={loadingSupplier}
                electricityBasis={electricityBasis}
                setElectricityBasis={setElectricityBasis}
                factors={supplierData?.getSupplier?.supplier?.emissionFactors}
                emissionFactorsYear={emissionFactorsYear}
                setEmissionFactorsYear={setEmissionFactorsYear}
            />
            <EmissionsTable supplierId={supplierId} />
        </Stack>
    );
};
