import { useEffect } from "react";

interface Supplier {
    id: string;
    name: string;
}

export function useRegisterVisit(supplier: Supplier | undefined) {
    useEffect(() => {
        if (!process.env.VITE_QUICK_ACCESS_URL || !supplier) {
            return;
        }

        const url = process.env.VITE_QUICK_ACCESS_URL;
        const link = `/supplier-profile/${supplier.id}/overview`;
        const tenant = localStorage.getItem("tenant");

        // Data to be sent in the request body
        const data = {
            entityId: `cat-${supplier.id}`,
            title: supplier.name,
            type: "SUPPLIER",
            date: new Date().toISOString(),
            link,
        };

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                ...(tenant ? { "x-tenant-id": tenant } : {}),
            },
            credentials: "include",
            body: JSON.stringify(data),
        }).catch(() => console.warn("Not able to register visit"));
    }, [supplier]);
}
