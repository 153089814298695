import { PlusCircle } from "@ignite-analytics/icons";
import { Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useIntl } from "react-intl";

import { OnboardingStatus } from "@/gql/graphql";

import { normalizeLocale } from "./utils";

dayjs.extend(utc);
dayjs.extend(timezone);

export const OnboardingEvaluatedActivity: React.FC<{
    key: string;
    approvedBy?: string;
    evaluatedAt?: string | null;
    onboardingStatus?: OnboardingStatus;
    supplierName: string;
}> = ({ approvedBy, evaluatedAt, onboardingStatus, supplierName }) => {
    const { formatMessage, locale, timeZone } = useIntl();
    dayjs.locale(normalizeLocale(locale));
    const theme = useTheme();
    if (onboardingStatus === undefined || onboardingStatus === OnboardingStatus.InOnboarding || !approvedBy) {
        return;
    }

    const formatOnboardingActivityMessage = (status: OnboardingStatus | undefined) => {
        {
            switch (status) {
                case OnboardingStatus.Approved:
                    return formatMessage({ defaultMessage: "approved" });
                case OnboardingStatus.Rejected:
                    return formatMessage({ defaultMessage: "rejected" });
                default:
                    return "";
            }
        }
    };

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginLeft: 1 }}>
            <PlusCircle fontSize="small" color="secondary" />
            <Typography variant="textSm">
                <span style={{ fontWeight: 500 }}>{approvedBy + " "}</span>
                {formatOnboardingActivityMessage(onboardingStatus) + " "}
                <span style={{ fontWeight: 500 }}>{supplierName + ". "}</span>
                <span style={{ color: theme.palette.text.secondary }}>
                    {formatMessage(
                        {
                            defaultMessage: ` {onboardingDate} ago`,
                        },
                        {
                            onboardingDate: dayjs(evaluatedAt).utc().tz(timeZone).fromNow(true),
                        }
                    )}
                </span>
            </Typography>
        </Stack>
    );
};

export const OnboardingInitiatedActivity: React.FC<{ supplierName: string; createdAt?: string | null }> = ({
    supplierName,
    createdAt,
}) => {
    const { formatMessage, locale, timeZone } = useIntl();
    dayjs.locale(normalizeLocale(locale));
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginLeft: 1 }}>
            <PlusCircle fontSize="small" color="secondary" />
            <Typography variant="textSm">
                <span style={{ fontWeight: 500 }}>{supplierName + " "}</span>
                {formatMessage({
                    defaultMessage: `was added for onboarding.`,
                }) + " "}
                <span style={{ color: theme.palette.text.secondary }}>
                    {formatMessage(
                        {
                            defaultMessage: ` {onboardingDate} ago`,
                        },
                        {
                            onboardingDate: dayjs(createdAt).utc().tz(timeZone).fromNow(true),
                        }
                    )}
                </span>
            </Typography>
        </Stack>
    );
};
