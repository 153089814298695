import { MinusCircle, PlusCircle } from "@ignite-analytics/icons";
import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Collapse, Paper, Stack, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { AnswerWithEmail } from "../InternalAnswers";

type InternalMetaAnswerTableProps = {
    answers: AnswerWithEmail[];
    valueCellProps?: {
        maxRating?: number;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueCellRenderer: FunctionComponent<any>;
};

export const InternalAnswerTable: React.FC<InternalMetaAnswerTableProps> = ({
    answers,
    valueCellProps = {},
    valueCellRenderer: ValueCellRenderer,
}) => {
    const { formatMessage } = useIntl();

    const [open, setOpen] = useState<boolean>(answers[0].question.type == "date" || answers[0].question.type == "text");
    const toggleOpen = () => setOpen(!open);

    const countString = `${answers.length} ${answers.length === 1 ? formatMessage({ defaultMessage: "response" }) : formatMessage({ defaultMessage: "responses" })}`;
    return (
        <Stack spacing={2}>
            <Typography variant="textXs">{countString}</Typography>
            <Paper>
                <Stack
                    direction="row"
                    width="97%"
                    justifyContent="space-between"
                    alignItems="center"
                    margin={2}
                    onClick={toggleOpen}
                    sx={{ cursor: "pointer" }}
                >
                    {open ? (
                        <>
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Hide answers" description="Hide answers button" />
                            </Typography>
                            <MinusCircle color="primary" fontSize="small" />
                        </>
                    ) : (
                        <>
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="Show answers" description="SHow answers button" />
                            </Typography>
                            <PlusCircle color="primary" fontSize="small" />
                        </>
                    )}
                </Stack>

                <Collapse in={open}>
                    {answers.map((answer) => {
                        const answerDate = `${formatMessage({ defaultMessage: "Submitted" })}: ${formatDateAsUTC(answer.createdAt || "")}`;
                        return (
                            <Stack
                                key={answer.id}
                                margin={2}
                                padding={2}
                                borderRadius={1}
                                sx={{ background: (theme) => theme.palette.background.paper }}
                            >
                                {ValueCellRenderer({ value: answer.value, ...valueCellProps })}
                                <Stack direction="row" justifyContent="space-between" marginTop={2}>
                                    <Typography variant="textSm">{answer.receiverEmail}</Typography>
                                    <Typography variant="textXs">{answerDate}</Typography>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Collapse>
            </Paper>
        </Stack>
    );
};
