import { gql, useQuery } from "@apollo/client";
import { Divider, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";

import {
    DatePeriodSelection,
    getStaticFromRelative,
    isRelativeDatePeriodOption,
    relativeDatePeriodOptions,
    toDatePeriodInput,
    useDatePeriodLabel,
} from "@/components/Common/spendUtils";
import { DateInterval } from "@/gql/graphql";

import { dateToString } from "./utils";

const getDatePeriod = gql(`
    query GetDatePeriod($input: DatePeriodInput!) {
        getDatePeriod(input: $input) {
            periodStart
            periodEnd
        }
    }
`);

type Props = {
    datePeriodSelection: DatePeriodSelection;
    onDatePeriodChange: (datePeriodSelection: DatePeriodSelection) => void;
};

export const PeriodSelector: React.FC<Props> = ({ datePeriodSelection, onDatePeriodChange }) => {
    //Need to get the last spend date to reference time periods
    const { data } = useQuery(getDatePeriod, {
        variables: {
            input: toDatePeriodInput({
                relative: relativeDatePeriodOptions.last12months,
                interval: DateInterval.Month,
            }),
        },
    });
    const lastSpendDate = data?.getDatePeriod.periodEnd ? new Date(data.getDatePeriod.periodEnd) : undefined;

    const handleDatePeriodChange = (e: SelectChangeEvent) => {
        const choice = e.target.value;
        if (isRelativeDatePeriodOption(choice)) {
            const option = relativeDatePeriodOptions[choice];
            onDatePeriodChange({
                relative: option,
                static: getStaticFromRelative(option, option.period == "month" ? lastSpendDate : undefined),
                interval: option.key === "last5Years" ? DateInterval.Year : DateInterval.Month,
            });
        }
    };

    const GetLabel = (datePeriod: DatePeriodSelection) => {
        return useDatePeriodLabel(datePeriod);
    };

    return (
        <Stack direction="row" alignItems="center" spacing={2} padding={0.5}>
            <Select
                value={datePeriodSelection.relative ? datePeriodSelection.relative.key : "custom"}
                onChange={handleDatePeriodChange}
                size="medium"
                sx={{ width: "200px", maxHeight: "44px" }}
            >
                {Object.entries(relativeDatePeriodOptions).map(([item, option]) => {
                    const datePeriod: DatePeriodSelection = {
                        relative: option,
                        static: getStaticFromRelative(option, option.period == "month" ? lastSpendDate : undefined),
                        interval: option.key === "last5Years" ? DateInterval.Year : DateInterval.Month,
                    };
                    const dateString = datePeriod.static
                        ? `${dateToString(new Date(datePeriod.static.from))} - ${dateToString(new Date(datePeriod.static.to))}`
                        : "";

                    return (
                        <MenuItem key={item} value={item}>
                            <Stack justifyContent="center">
                                <Typography variant="textSm" fontWeight={500}>
                                    {GetLabel(datePeriod)}
                                </Typography>
                                <Typography variant="text2xs" fontWeight={500}>
                                    {dateString}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    );
                })}
                <Divider style={{ marginBlock: "0px" }} />
            </Select>
        </Stack>
    );
};
