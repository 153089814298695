import { Button, Card, CardContent, CardHeader, Divider, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { EmissionsDevelopmentChart } from "@/components/Pages/Emissions/EmissionsDevelopmentChart";
import { graphql } from "@/gql";
import { EmissionDevelopment_SupplierFragment } from "@/gql/graphql";
import { track } from "@/lib/track";

// TODO: need to figure out how to pass the emissions input in the query to make it dynamic
graphql(`
    fragment EmissionDevelopment_Supplier on Supplier {
        id
        emissions(
            emissionsInput: {
                electricityBasis: $electricityBasis
                startYear: 2020
                endYear: 2024
                filter: { businessUnitIds: [], spendCategoryIds: [] }
            }
        ) {
            ...EmissionsDevelopmentChart_TotalEmissions
        }
    }
`);

interface EmissionDevelopmentProps {
    supplier: EmissionDevelopment_SupplierFragment;
    loading: boolean;
}

export const EmissionDevelopment: React.FC<EmissionDevelopmentProps> = ({ supplier, loading }) => {
    const navigate = useNavigate();
    const { formatNumber } = useIntl();
    const [isBarHovered, setIsBarHovered] = useState(false);
    const handleMouseOver = () => setIsBarHovered(true);
    const handleMouseOut = () => setIsBarHovered(false);

    const totalEmissions = supplier.emissions?.reduce((acc, curr) => acc + curr.totalEmissions.value, 0) ?? 0;

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack spacing={1}>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Emission development" />
                            </Typography>
                            <Typography variant="textSm" sx={{ opacity: 0.6 }}>
                                <FormattedMessage defaultMessage="Yearly in tCO₂e" />
                            </Typography>
                        </Stack>
                        <Button
                            color="secondary"
                            size="xsmall"
                            onClick={() => {
                                track("Supplier Profile: Clicked Emissions page");
                                navigate(`/supplier-profile/${supplier.id}/emissions`);
                            }}
                        >
                            <FormattedMessage defaultMessage="Go to Emissions" />
                        </Button>
                    </Stack>
                }
            ></CardHeader>
            <CardContent onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <EmissionsDevelopmentChart
                    emissions={supplier.emissions}
                    loading={loading}
                    isBarHovered={isBarHovered}
                />
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Total emissions" />
                    </Typography>
                    <Typography variant="textMd" fontWeight={700}>
                        <FormattedMessage
                            defaultMessage="{value} tCO₂e"
                            values={{ value: formatNumber(totalEmissions) }}
                        />
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
