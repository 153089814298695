import { Card, CardContent, Divider, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer } from "recharts";

import { EninLogo } from "@/assets/EninLogo";
import { PercentageChip } from "@/components/Common/ValueChip";

import { getColorFactory } from "./helpers";

type PotentialChartProps = {
    data: {
        potential: {
            name: string;
            value: number;
        }[];
        spendGrowth: number;
        roce: number;
        shareOfWallet: number;
        ebitMargin: number;
    };
};

export const PotentialChart: React.FC<PotentialChartProps> = ({ data }) => {
    const theme = useTheme();
    const radialData = [{ value: data.potential[0]?.value ?? 0, fill: theme.palette.secondary.light }];
    const { formatMessage } = useIntl();

    console.log(theme.palette);
    return (
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Potential" />
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <RadialBarChart
                            innerRadius="60%"
                            outerRadius="90%"
                            startAngle={90}
                            endAngle={-270}
                            data={radialData}
                        >
                            <PolarAngleAxis type="number" domain={[0, 100]} angleAxisId={0} tick={false} />
                            <RadialBar
                                background
                                dataKey="value"
                                angleAxisId={0}
                                isAnimationActive={false}
                                label={({ value }) => {
                                    const valueString = `${value}/100`;
                                    return (
                                        <foreignObject x="25%" y="42%" width="50%" height="50%">
                                            <Stack style={{ textAlign: "center" }}>
                                                <Typography fontWeight={700} fontSize={32}>
                                                    {valueString}
                                                </Typography>
                                            </Stack>
                                        </foreignObject>
                                    );
                                }}
                            />
                        </RadialBarChart>
                    </ResponsiveContainer>
                    <Stack spacing={2} justifyContent="center">
                        <Stack spacing={2} direction="row" justifyContent="center">
                            <PercentageChip
                                value={data.spendGrowth}
                                label={formatMessage({ defaultMessage: "Spend growth" })}
                                color={getColorFactory(0.5, 2, data.spendGrowth, false)(theme)}
                                tooltipTitle={formatMessage({ defaultMessage: "Spend growth" })}
                                tooltipText={formatMessage({
                                    defaultMessage:
                                        "Spend growth indicates how your overall spend has developed towards the supplier. Growth in overall spend can indicate stronger possibilities for renegotiation of prices. Spend growth is measured by the compound annual growth rate (CAGR) within the selected time period. CAGR is calculated by comparing spend the last 12 months to the spend 12 months before.",
                                })}
                            />
                            <PercentageChip
                                value={data.roce}
                                label={formatMessage({ defaultMessage: "ROCE" })}
                                color={getColorFactory(-0.5, 0.15, data.roce, false)(theme)}
                                tooltipTitle={formatMessage({ defaultMessage: "ROCE" })}
                                tooltipText={formatMessage({
                                    defaultMessage:
                                        "Return on capital employed (ROCE) is a profitability ratio that measures how efficiently the supplier is using its capital. ROCE is calculated by dividing operating profit (EBIT) to capital employed for the latest available financial data. The rate is typically compared to the required rate of return, often expressed by the weighted average cost of capital (WACC).",
                                })}
                            />
                        </Stack>
                        <Stack spacing={2} direction="row" justifyContent="center">
                            <PercentageChip
                                value={data.shareOfWallet}
                                label={formatMessage({ defaultMessage: "Share of wallet" })}
                                color={getColorFactory(0.01, 0.1, data.shareOfWallet, false)(theme)}
                                tooltipTitle={formatMessage({ defaultMessage: "Share of wallet" })}
                                tooltipText={formatMessage({
                                    defaultMessage:
                                        "The share of wallet measures your share of the supplier's revenue, and is calculated by dividing your spend with the supplier's revenue for the latest available financial data. A high share of wallet indicates that you are an important customer for the supplier and implies a more critical customer (and supplier) dependency. The share of wallet highlights your relative bargaining power towards the supplier.",
                                })}
                            />
                            <PercentageChip
                                value={data.ebitMargin}
                                label={formatMessage({ defaultMessage: "EBIT margin" })}
                                color={getColorFactory(-0.05, 0.3, data.ebitMargin, false)(theme)}
                                tooltipTitle={formatMessage({ defaultMessage: "EBIT margin" })}
                                tooltipText={formatMessage({
                                    defaultMessage:
                                        "Measures the supplier's profitability, and is calculated by dividing earnings before interest and taxes (EBIT or operating profit) to revenue for the latest available financial data. The profit margin can be compared against relevant competitors and help determine if the margin is in line with the competetive landscape. A more profitable supplier indicates a stronger possibility for renegotiation of prices, all other things being equal.",
                                })}
                            />
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="The potential analysis rates your suppliers through a potential rating based on four different metrics: share of wallet, spend development (CAGR), EBIT margin and return on capital employed (ROCE)." />
                    </Typography>
                    <Stack spacing={2} alignItems="flex-end" marginTop={4}>
                        <EninLogo />
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};
