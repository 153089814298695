import { useCallback, useEffect } from "react";

export function useKeyboardShortcut(key: string, callback: (event: KeyboardEvent) => void) {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.ctrlKey || event.metaKey) {
                if (event.key === key) {
                    event.preventDefault();
                    callback(event);
                }
            }
        },
        [key, callback]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);
}
