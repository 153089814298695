/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Stack } from "@mui/material";
import React from "react";
import { TooltipProps } from "recharts";

interface CustomTooltipProps extends TooltipProps<any, any> {
    chartInfo: React.FC<{ active: boolean; payload: any[]; label: string }>;
    color: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, chartInfo, color }) => {
    if (active && payload && payload.length) {
        return (
            <Card sx={{ padding: 2, minWidth: 150, position: "relative" }}>
                <Stack direction="row" spacing={1}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 16,
                            bottom: 16,
                            left: 8,
                            width: 8,
                            borderRadius: 4,
                            backgroundColor: color,
                        }}
                    />
                    {chartInfo({ active, payload, label })}
                </Stack>
            </Card>
        );
    }

    return null;
};
