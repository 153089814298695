import { useQuery } from "@apollo/client";
import { ChevronDown, ChevronUp } from "@ignite-analytics/icons";
import { formatDateAsUTC, FormatType } from "@ignite-analytics/locale";
import { Accordion, Chip, CircularProgress, IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ExternalAnswers_AssessmentFragment, ExternalAnswers_SectionFragment, QuestionType } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { NotResponded } from "../Components/NotResponded";

import { ExternalAnswerCard } from "./ExternalAnswerCard";

const getQuestionnaireAndAssessmentDocument = graphql(`
    query ExternalAnswers_GetQuestionnaireAndAssessment(
        $getQuestionnaireInput: GetQuestionnaireInput!
        $getAssessmentInput: GetAssessmentInput!
    ) {
        getQuestionnaire(input: $getQuestionnaireInput) {
            questionnaire {
                id
                ...ExternalAnswers_Questionnaire
            }
        }
        getAssessment(input: $getAssessmentInput) {
            assessment {
                id
                ...ExternalAnswers_Assessment
            }
        }
    }
`);

graphql(`
    fragment ExternalAnswers_Answer on Answer {
        id
        questionId
        question {
            ...ExternalAnswers_Question
        }
        value
    }
    fragment ExternalAnswers_Assessment on Assessment {
        id
        answers {
            ...ExternalAnswers_Answer
        }
        score
        submittedAt
    }

    fragment ExternalAnswers_Questionnaire on Questionnaire {
        id
        sections {
            ...ExternalAnswers_Section
        }
    }

    fragment ExternalAnswers_Section on Section {
        id
        name
        order
        questions {
            ...ExternalAnswers_Question
        }
    }

    fragment ExternalAnswers_Question on Question {
        id
        title
        description
        type
        order
        maxRating
    }
`);

export type ExternalAnswersProps = {
    assessmentId: string;
    questionnaireId: string;
};

export const ExternalAnswers: React.FC<ExternalAnswersProps> = ({ assessmentId, questionnaireId }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const { data, loading } = useQuery(getQuestionnaireAndAssessmentDocument, {
        skip: !assessmentId || !questionnaireId,
        variables: {
            getQuestionnaireInput: { id: questionnaireId },
            getAssessmentInput: { id: assessmentId },
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get assessment data",
                    description: "Get assessment data fail message",
                }),
                severity: "error",
            });
        },
    });
    if (loading) {
        return (
            <Stack padding={2} boxSizing="border-box">
                <Stack direction="row" alignItems="center" justifyContent="center" height="400px" width="100%">
                    <CircularProgress />
                </Stack>
            </Stack>
        );
    }

    const assessment = data?.getAssessment.assessment;
    const questionnaire = data?.getQuestionnaire.questionnaire;

    if (!assessment || !questionnaire) {
        return null;
    }

    const submittedAt = assessment.submittedAt;
    if (!submittedAt) {
        return <NotResponded />;
    }

    const formattedDate = formatDateAsUTC(submittedAt, { formatType: FormatType.onlyDate });
    const sections = [...(data?.getQuestionnaire.questionnaire.sections ?? [])];

    return (
        <Stack spacing={2} margin={2}>
            <Stack width="100%" direction="row" spacing={2} alignItems="center">
                <Paper variant="outlined" sx={{ width: 333, px: 2 }}>
                    <Stack alignItems="flex-start" my={2} spacing={1}>
                        <Typography variant="textXs">
                            <FormattedMessage
                                defaultMessage="Submission date"
                                description="Submission date header on Assessment tab"
                            />
                        </Typography>
                        <Chip label={formattedDate} />
                    </Stack>
                </Paper>
                <Paper variant="outlined" sx={{ width: 333, px: 2 }}>
                    <Stack alignItems="flex-start" my={2} spacing={1}>
                        <Typography variant="textXs">
                            <FormattedMessage defaultMessage="Score" description="Score header on Assessment tab" />
                        </Typography>
                        <Chip label={assessment.score} />
                    </Stack>
                </Paper>
            </Stack>
            <Stack padding={0}>
                {sections
                    .sort((a, b) => a.order - b.order)
                    .map((section) => (
                        <Section key={section.id} section={section} assessment={assessment} />
                    ))}
            </Stack>
        </Stack>
    );
};

type SectionProps = {
    section: ExternalAnswers_SectionFragment;
    assessment: ExternalAnswers_AssessmentFragment;
};

const Section: React.FC<SectionProps> = ({ section, assessment }) => {
    const [open, setOpen] = useState<boolean>(section.order === 0);
    const toggleOpen = () => setOpen((prev) => !prev);
    const questions = [...section.questions];

    return (
        <Stack key={section.id} marginY={2}>
            <Accordion expanded={open} sx={{ border: "none", boxShadow: "none" }}>
                <Stack direction="row" alignItems="center">
                    <IconButton
                        size="small"
                        onClick={toggleOpen}
                        sx={{
                            "&:focus": {
                                outline: "none",
                                border: "none",
                                boxShadow: "none",
                            },
                        }}
                    >
                        {open ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                    <Typography variant="textMd" fontWeight={500}>
                        {section.name}
                    </Typography>
                </Stack>

                {questions
                    .sort((a, b) => a.order - b.order)
                    .map((question) => {
                        const answer = assessment.answers.find((answer) => answer.questionId === question.id);
                        return (
                            <ExternalAnswerCard
                                key={question.id}
                                value={
                                    (question.type === QuestionType.MultiSelect
                                        ? answer?.value?.join(", ")
                                        : answer?.value[0]) ?? ""
                                }
                                question={question}
                            />
                        );
                    })}
            </Accordion>
        </Stack>
    );
};
