import { getLocale } from "@ignite-analytics/locale";
import { darken, Theme } from "@mui/material";
import { Rectangle } from "recharts";

import { DateInterval } from "@/gql/graphql";

export const getShadeOfLight = (index: number, maxIndex: number, theme: Theme) => {
    const adjustmentFactor = (index * 0.6) / maxIndex;
    return darken(theme.palette.secondary.light, adjustmentFactor);
};

export const formatSpend = (value: number, compact: boolean, currency?: string) => {
    if (!currency) {
        return value.toLocaleString(getLocale(), {
            notation: compact ? "compact" : "standard",
            maximumFractionDigits: 0,
        });
    }
    return value.toLocaleString(getLocale(), {
        style: "currency",
        currency,
        notation: compact ? "compact" : "standard",
        maximumFractionDigits: 0,
    });
};

export const dateToString = (date: Date) => {
    const language = getLocale();
    return date.toLocaleDateString(language, {
        day: "numeric",
        month: "short",
        year: "numeric",
    });
};

export const getLabel = (dateString: string, interval: DateInterval): string => {
    const date = new Date(dateString);
    switch (interval) {
        case DateInterval.Year:
            return date.getFullYear().toString();
        case DateInterval.Month:
        default:
            return getMonth(dateString);
    }
};

type Variant = "short" | "long";
export const getMonth = (monthString: string, variant: Variant = "short") => {
    const language = getLocale();
    const date = new Date(`${monthString} 01 2000`);
    return date.toLocaleDateString(language, {
        month: variant,
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getSplitBarShape = (props: any, categories: string[], isChartHovered: boolean) => {
    const nonzeroCategories = categories.filter((category) => {
        const isNonZero = props.payload[category] > 0;
        return isNonZero;
    });

    const categoryIndex = nonzeroCategories.indexOf(props.dataKey);

    let radius: [number, number, number, number];
    switch (categoryIndex) {
        case 0:
            if (nonzeroCategories.length == 1) {
                radius = [8, 8, 8, 8];
            } else {
                radius = [0, 0, 8, 8];
            }
            break;
        case nonzeroCategories.length - 1:
            radius = [8, 8, 0, 0];
            break;
        default:
            radius = [0, 0, 0, 0];
    }

    {
        const { x, y, width, height } = props;
        return (
            <Rectangle
                x={x}
                y={y}
                width={width}
                height={height}
                fill={props.fill}
                radius={radius}
                opacity={isChartHovered ? 0.6 : 1}
            />
        );
    }
};

export type QueryStatus = "loading" | "noData" | "error" | "success";
