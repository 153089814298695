import { useQuery } from "@apollo/client";
import { DotsHorizontal } from "@ignite-analytics/icons";
import { Card, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FinancialsIcon } from "@/assets/FinancialsIcon";
import { NoData } from "@/components/Common/NoData";
import { graphql } from "@/gql";
import { useAlert } from "@/providers";

import { calculateRisk } from "./helpers";
import { PotentialChart } from "./PotentialChart";
import { RevenueEbitChart } from "./RevenueEbitChart";
import { RiskChart } from "./RiskChart";

const getFinancialsSupplier = graphql(`
    query Financials_GetSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                ...Financials_Supplier
            }
        }
    }
`);

graphql(`
    fragment Financials_Supplier on Supplier {
        id
        enrichments(enrichmentPrecedence: [ENIN_ENTITY_INSIGHTS, INFOBEL_BUSINESS_SEARCH]) {
            ... on Company {
                latestFinancialStatement {
                    ... on financialStatement {
                        currency
                        totalAssets
                        currentLiabilities
                        currentAssets
                        ebit
                        ebitMargin
                        equityRatio
                        currentRatio
                    }
                }
                financialStatements {
                    ... on financialStatement {
                        accountType
                        currency
                        ebitMargin
                        revenue
                        year
                    }
                }
            }
        }
        savingsEstimate {
            potential
            factors {
                shareOfWallet {
                    value
                }
                ebitMargin {
                    value
                }
                roce {
                    value
                }
                spendGrowth {
                    value
                }
            }
        }
    }
`);

export type FinancialData = { year: number; revenue: number; ebitMargin: number; currency: string };

interface FinancialsPageProps {
    supplierId: string;
}

export const FinancialsPage: React.FC<FinancialsPageProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const { data, loading, error } = useQuery(getFinancialsSupplier, {
        errorPolicy: "all",
        variables: { input: { id: supplierId, getNormalized: false } },
        skip: supplierId === "",
        onError: (error) => {
            alertUser({
                value: formatMessage({
                    defaultMessage: `Could not get financial data`,
                }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "supplier-profile", message: "Failed to get financials supplier" },
            });
        },
    });

    const enrichments = data?.getSupplier?.supplier?.enrichments;
    const financialData: FinancialData[] = (
        enrichments?.financialStatements
            ?.filter((s) => !!s && s.accountType == "annual_company_accounts")
            .map((statement) => {
                return {
                    year: statement?.year ?? 0,
                    revenue: statement?.revenue ?? 0,
                    ebitMargin: statement?.ebitMargin ?? 0,
                    currency: statement?.currency ?? "",
                };
            }) ?? []
    )
        .reverse()
        .slice(-4);

    const ebitMarginMin = Math.min(...financialData.map((data) => data.ebitMargin));
    const ebitMarginMax = Math.max(...financialData.map((data) => data.ebitMargin));
    const revenuesMax = Math.max(...financialData.map((data) => data.revenue));
    const options = {
        revenuesMax: revenuesMax,
        ebitMarginMin: ebitMarginMin,
        ebitMarginMax: ebitMarginMax,
    };

    const roce =
        (enrichments?.latestFinancialStatement?.ebit ?? 0) /
        ((enrichments?.latestFinancialStatement?.totalAssets ?? 1) -
            (enrichments?.latestFinancialStatement?.currentLiabilities ?? 0));

    const risk = {
        risk: [
            {
                name: "Risk",
                value:
                    calculateRisk(
                        roce,
                        enrichments?.latestFinancialStatement?.currentRatio ?? 0,
                        enrichments?.latestFinancialStatement?.equityRatio ?? 0
                    ) ?? 0,
            },
        ],
        liquidity:
            (enrichments?.latestFinancialStatement?.currentAssets ?? 0) /
            (enrichments?.latestFinancialStatement?.currentLiabilities ?? 1),
        solvency: enrichments?.latestFinancialStatement?.equityRatio ?? 0,
        roce: roce,
    };

    const potential = {
        potential: [
            {
                name: "Potential",
                value: Number(data?.getSupplier.supplier.savingsEstimate?.potential.toFixed(0)),
            },
        ],
        spendGrowth: data?.getSupplier.supplier.savingsEstimate?.factors?.spendGrowth?.value ?? 0,
        roce: data?.getSupplier.supplier.savingsEstimate?.factors?.roce?.value ?? 0,
        shareOfWallet: data?.getSupplier.supplier.savingsEstimate?.factors?.shareOfWallet?.value ?? 0,
        ebitMargin: data?.getSupplier.supplier.savingsEstimate?.factors?.ebitMargin?.value ?? 0,
    };

    return (
        <Stack gap={3} width="100%">
            <Stack spacing={2} direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                    <FinancialsIcon />
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financials" />
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                    {false && ( //add back when we have export functionality
                        <IconButton color="secondary" size="small">
                            <DotsHorizontal fontSize="small" />
                        </IconButton>
                    )}
                </Stack>
            </Stack>

            {loading ? (
                <Skeleton variant="rectangular" height={400} />
            ) : financialData.length == 0 ? (
                <Card sx={{ padding: 2 }}>
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Financial data" />
                    </Typography>
                    <NoData message={formatMessage({ defaultMessage: "No financial data available" })} height="340px" />
                </Card>
            ) : (
                <RevenueEbitChart financialData={financialData} config={options} />
            )}
            <Grid container spacing={2}>
                <Grid item md={12} lg={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={500} />
                    ) : enrichments?.latestFinancialStatement ? (
                        <RiskChart data={risk} />
                    ) : (
                        <Card sx={{ padding: 2 }}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="textXl" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Risk" />
                                </Typography>
                            </Stack>
                            <NoData
                                message={formatMessage({ defaultMessage: "Could not calculate risk" })}
                                height="452px"
                            />
                        </Card>
                    )}
                </Grid>
                <Grid item md={12} lg={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" height={500} />
                    ) : !data || !data.getSupplier.supplier.savingsEstimate || error ? (
                        <Card sx={{ padding: 2 }}>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="textXl" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Potential" />
                                </Typography>
                            </Stack>
                            <NoData
                                title={formatMessage({
                                    defaultMessage: "Could not calculate potential",
                                })}
                                message={formatMessage({
                                    defaultMessage:
                                        "To get a potential score, the supplier needs recent spend. If we do not find enough relevant factors, we don't calculate the potential score.",
                                })}
                                height="452px"
                            />
                        </Card>
                    ) : (
                        <PotentialChart data={potential} />
                    )}
                </Grid>
            </Grid>
        </Stack>
    );
};
