import { useQuery } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    AboutSupplierModal_ColumnsFragment,
    AboutSupplierModal_SupplierFragment,
    ClassificationOptions,
    SupplierCustomFieldType,
} from "@/gql/graphql";

import { InfoRow } from "./utils";

graphql(`
    fragment AboutSupplierModal_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        customFields {
            name
            dataJson
            fieldType
            fieldId
        }
        onboarding {
            status
            approverId
            evaluatedAt
        }
    }

    fragment AboutSupplierModal_Columns on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on ClassificationOptions {
                groups {
                    id
                    value
                    level
                }
            }
        }
    }
`);

const getSupplierTableColumnsQuery = graphql(`
    query getSupplierTableColumns {
        getSupplierTableMeta {
            columns {
                ...AboutSupplierModal_Columns
            }
        }
    }
`);

interface AboutSupplierModalProps {
    open: boolean;
    onClose: () => void;
    isEditor: boolean;
    supplier: AboutSupplierModal_SupplierFragment;
    approver?: string;
}

export const AboutSupplierModal: React.FC<AboutSupplierModalProps> = ({ open, onClose, supplier, approver }) => {
    const { formatMessage } = useIntl();
    const [riskValues, customFields] = supplier.customFields.reduce(
        (acc, field) => {
            if (field.fieldType === SupplierCustomFieldType.Risk) {
                acc[0].push(field);
            }
            // excluding assessment fields
            else if (
                field.fieldType !== SupplierCustomFieldType.Assessment &&
                field.fieldType !== SupplierCustomFieldType.Nace
            ) {
                acc[1].push(field);
            }
            return acc;
        },
        [[], []] as [typeof supplier.customFields, typeof supplier.customFields]
    );

    const { data } = useQuery(getSupplierTableColumnsQuery);
    const columns = data?.getSupplierTableMeta.columns ?? [];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                },
            }}
        >
            <DialogTitle>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Stack>
                    <Typography variant="textXl">
                        <FormattedMessage defaultMessage="About" description="supplier information header" />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3, marginBottom: 3 }}>
                <Stack rowGap={2}>
                    {/* General information */}
                    <Grid container rowSpacing={2}>
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                        />
                        <InfoRow
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                    </Grid>
                    <Divider />
                    {/* Onboarding */}
                    {supplier.onboarding && (
                        <>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={approver ?? ""}
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={supplier.onboarding.evaluatedAt ?? ""}
                                    type={SupplierCustomFieldType.Date}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                            <Divider />
                        </>
                    )}
                    {/* Nace-code and risk values */}
                    <Grid container rowSpacing={2}>
                        <InfoRow name="NACE" value={supplier.nace ?? ""} type={SupplierCustomFieldType.Text} />
                        {riskValues.map((field) => {
                            const value = JSON.parse(field.dataJson);
                            return <InfoRow key={field.name} name={field.name} value={value} type={field.fieldType} />;
                        })}
                    </Grid>
                    <Divider />
                    {/* Custom fields */}
                    <Grid container rowSpacing={2}>
                        {customFields
                            .sort((a, b) => a.fieldType?.toString().localeCompare(b.fieldType?.toString()))
                            .reduce((acc: JSX.Element[], field) => {
                                // need special handling of classification fields
                                if (field.fieldType !== SupplierCustomFieldType.Classification) {
                                    const value = JSON.parse(field.dataJson);
                                    return [
                                        ...acc,
                                        <InfoRow
                                            key={field.name}
                                            name={field.name}
                                            value={value}
                                            type={field.fieldType}
                                        />,
                                    ];
                                }

                                // get the classification column
                                const column = columns.find(
                                    (tc: AboutSupplierModal_ColumnsFragment) => tc.id === field.fieldId
                                );
                                const c = JSON.parse(field.dataJson);
                                if (!column || !c) {
                                    return [
                                        ...acc,
                                        <InfoRow key={field.name} name={field.name} value="" type={field.fieldType} />,
                                    ];
                                }

                                // get the values of the classification groups from the ids in the custom field (dataJson)
                                const values = c.reduce((a: string[], v: string) => {
                                    const group = (column.typeOptions as ClassificationOptions)?.groups?.find(
                                        (g) => g.id === v
                                    );
                                    if (!group?.id) {
                                        return a;
                                    }
                                    return [...a, group?.value];
                                }, []);

                                if (values.length === 0) {
                                    return acc;
                                }
                                return [
                                    ...acc,
                                    <InfoRow
                                        key={field.name}
                                        name={field.name}
                                        value={values}
                                        type={field.fieldType}
                                    />,
                                ];
                            }, [])}
                    </Grid>
                    <Divider />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    <FormattedMessage defaultMessage="Close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
