import { useIntl } from "react-intl";

import { DatePeriodInput, DateInterval, PredefinedMetric, IntervalResult } from "@/gql/graphql";

import { CategoryData } from "../Pages/Spend/CategoryChart";

export type DatePeriodSelection = {
    relative?: {
        key: "last12months" | "last6months" | "last3months" | "lastYear" | "thisYear" | "last5Years";
        period: "month" | "year";
        length: number;
        offset: number;
    };
    static?: {
        from: Date;
        to: Date;
    };
    interval: DateInterval;
};

export function toApiDatePeriod(period: NonNullable<DatePeriodSelection["relative"]>["period"]): DateInterval {
    switch (period) {
        case "month":
            return DateInterval.Month;
        case "year":
            return DateInterval.Year;
    }
}

// Convert DatePeriodSelection to DatePeriodInput for spend endpoints
export function toDatePeriodInput(selection: DatePeriodSelection, relative: boolean = true): DatePeriodInput {
    const timezoneOffset = new Date().getTimezoneOffset();
    if (relative && selection.relative) {
        return {
            relative: {
                period: toApiDatePeriod(selection.relative.period),
                length: selection.relative.length,
                offset: selection.relative.offset ?? 0,
            },
        };
    }

    return {
        static: selection.static
            ? {
                  from: new Date(selection.static.from.getTime() - timezoneOffset * 60 * 1000)
                      .toISOString()
                      .split("T")[0],
                  to: new Date(selection.static.to.getTime() - timezoneOffset * 60 * 1000).toISOString().split("T")[0],
              }
            : undefined,
    };
}

export const relativeDatePeriodOptions = {
    thisYear: {
        period: "year",
        length: 1,
        key: "thisYear",
        offset: -1,
    },
    lastYear: {
        period: "year",
        length: 1,
        key: "lastYear",
        offset: 0,
    },
    last5Years: {
        period: "year",
        length: 5,
        key: "last5Years",
        offset: -1,
    },
    last12months: {
        period: "month",
        length: 12,
        key: "last12months",
        offset: 0,
    },
    last6months: {
        period: "month",
        length: 6,
        key: "last6months",
        offset: 0,
    },
    last3months: {
        period: "month",
        length: 3,
        key: "last3months",
        offset: 0,
    },
} as const;

export type RelativeDatePeriodOption = keyof typeof relativeDatePeriodOptions;

export function isRelativeDatePeriodOption(key: string): key is RelativeDatePeriodOption {
    return key in relativeDatePeriodOptions;
}

export function getStaticFromRelative(
    relative: NonNullable<DatePeriodSelection["relative"]>,
    referenceDate: Date = new Date()
): NonNullable<DatePeriodSelection["static"]> {
    const ref = referenceDate;
    const from = new Date(ref);
    const to = new Date(ref);
    const now = new Date();
    if (relative.period === "year") {
        switch (relative.key) {
            case "thisYear":
                from.setMonth(0);
                from.setDate(1);
                to.setFullYear(now.getFullYear());
                to.setMonth(now.getMonth());
                to.setDate(now.getDate());
                break;
            case "last5Years":
                from.setFullYear(from.getFullYear() - 4);
                from.setMonth(0);
                from.setDate(1);
                break;
            case "lastYear":
                from.setFullYear(from.getFullYear() - 1);
                from.setMonth(0);
                from.setDate(1);
                to.setFullYear(to.getFullYear() - 1);
                to.setMonth(11);
                to.setDate(31);
                break;
            default:
                from.setFullYear(from.getFullYear() - relative.offset);
                from.setMonth(0);
                from.setDate(1);
                to.setFullYear(to.getFullYear() - relative.offset);
                to.setMonth(11);
                to.setDate(31);
        }
    } else {
        from.setDate(1);
        from.setMonth(from.getMonth() - relative.length + 1);
    }
    return { from, to };
}

export const useDatePeriodLabel = (datePeriod: DatePeriodSelection) => {
    const { formatMessage } = useIntl();
    switch (datePeriod.relative?.key) {
        case "thisYear":
            return formatMessage({ defaultMessage: "This year" });
        case "lastYear":
            return formatMessage({ defaultMessage: "Last year" });
        case "last5Years":
            return formatMessage({ defaultMessage: "Last 5 years" });
        case "last12months":
        case "last6months":
        case "last3months":
            return formatMessage({ defaultMessage: "Last {months} months" }, { months: datePeriod.relative.length });
        default:
            return formatMessage({ defaultMessage: "This year" });
    }
};

export type Insight = {
    description: string;
    takeAction: (() => void) | undefined;
    learnMore: (() => void) | undefined;
    chart: "spendDevelopment" | "transaction" | "category" | "department" | "emissions";
};

export const parseCategoryData = (intervalResults?: IntervalResult[]) => {
    if (!intervalResults) {
        return {
            categories: [],
            categoryData: [],
            totalSpend: 0,
        };
    }
    const categories: string[] = intervalResults
        .map((r) => r.results.map((r) => r.label))
        .flat()
        .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

    const categoryData = intervalResults.map((result) => {
        const periodData: CategoryData = {
            period: result.intervalLabel,
        };
        categories.forEach((category) => {
            periodData[category] = 0;
        });

        result.results.forEach(({ label, metrics }) => {
            periodData[label] = metrics.find((m) => m.metric === PredefinedMetric.Spend)?.value ?? 0;
        });

        return periodData;
    });

    const totalSpend = categoryData.reduce((acc, curr) => {
        return (
            Number(acc) +
            Number(
                Object.values(curr).reduce((innerAcc, value) => {
                    return Number(innerAcc) + (typeof value === "number" ? value : 0);
                }, 0)
            )
        );
    }, 0);

    return {
        categories,
        categoryData,
        totalSpend,
    };
};
