import { formatDateAsUTC } from "@ignite-analytics/locale";
import { LinearProgress, Paper, Rating, Stack, Theme, Typography, styled } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { InternalAnswers_QuestionFragment, QuestionType } from "@/gql/graphql";

import { BarChart } from "../Components/BarChart";
import { groupBy } from "../utils";

import { AnswerWithEmail } from "./InternalAnswers";
import { InternalAnswerList } from "./Table/InternalAnswerList";
import { InternalAnswerTable } from "./Table/InternalAnswerTable";

type InternalAnswerCardProps = {
    question: InternalAnswers_QuestionFragment;
    answers?: AnswerWithEmail[];
};
export const InternalAnswerCard: React.FC<InternalAnswerCardProps> = ({ question, answers }) => {
    if (!answers || answers.length === 0) {
        return <NoAnswers />;
    }

    const title = `${question.order + 1}. ${question?.title}`;
    return (
        <Paper>
            <Stack margin={3}>
                <Stack marginX={3}>
                    <Typography marginTop={2} variant="textSm" fontWeight={500}>
                        {title}
                    </Typography>
                    <Stack marginX={3} marginY={2} spacing={2} marginTop={question.description ? 2 : 0}>
                        {question.description && <QuestionDescription description={question.description} />}
                        <AnswersRender question={question} answers={answers} />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

const NoAnswers: React.FC = () => {
    const { formatMessage } = useIntl();
    return (
        <Typography variant="textMd">
            {formatMessage({
                defaultMessage: "No answers",
                description: "No answer placeholder",
            })}
        </Typography>
    );
};

const QuestionDescription: React.FC<{ description: string }> = ({ description }) =>
    description ? (
        <Typography variant="textXs" marginBottom={1}>
            {description}
        </Typography>
    ) : null;

const AnswersRender = ({
    question,
    answers,
}: {
    question: InternalAnswers_QuestionFragment;
    answers: AnswerWithEmail[];
}) => {
    const { formatMessage } = useIntl();
    const total = answers?.length ?? 0;

    switch (question.type) {
        case QuestionType.Boolean: {
            return (
                <Stack spacing={2}>
                    <BarChart
                        bars={[
                            {
                                title: formatMessage({ defaultMessage: "Yes", description: "Bar chart Yes" }),
                                value: answers.filter(({ value }) => value[0] === "1").length,
                                total: total,
                            },
                            {
                                title: formatMessage({ defaultMessage: "No", description: "Bar chart No" }),
                                value: answers.filter(({ value }) => value[0] === "0").length,
                                total: total,
                            },
                        ]}
                    />
                    <InternalAnswerTable
                        answers={answers}
                        valueCellRenderer={({ value }: { value: string }) => (
                            <Typography variant="textMd">{value == "1" ? "Yes" : "No"}</Typography>
                        )}
                    />
                </Stack>
            );
        }
        case QuestionType.Rating: {
            const averageRating = answers.reduce((sum, { value }) => sum + Number(value), 0) / answers.length;
            const maxRating = question.maxRating ?? 5;

            const counts = Array.from(
                { length: maxRating },
                (_, i) => answers.filter(({ value }) => Number(value) === i + 1).length
            );
            const highestCount = Math.max(...counts);

            const valueMap = counts.map((count, i) => {
                const percentage = count === 0 ? 1 : (count * 100) / highestCount;
                return [percentage, `${i + 1}`, `(${count})`];
            });
            return (
                <Stack marginY={2} width="100%" spacing="4px">
                    <Typography color={(theme) => theme.palette.text.primary} variant="textSm">
                        <FormattedMessage defaultMessage="Average rating" description="Average rating message" />
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography color={(theme) => theme.palette.text.primary} variant="displayMd">
                            {averageRating.toFixed(1)}
                        </Typography>
                        <Rating max={maxRating} value={averageRating} readOnly size="small" />
                    </Stack>
                    {valueMap.reverse().map(([percentage, rating, count], i) => (
                        <Stack key={i} alignItems="center" direction="row" width="100%">
                            <Typography sx={{ width: 2 }} variant="textXs">
                                {rating}
                            </Typography>
                            <Rating max={1} value={1} readOnly size="small" sx={{ marginLeft: 1.5 }} />
                            <Stack width="100%" marginLeft={1}>
                                <RoundedLinearProgress
                                    color="secondary"
                                    variant="determinate"
                                    value={Number(percentage)}
                                    sx={{ marginX: 2 }}
                                />
                            </Stack>
                            <Typography variant="textXs">{count}</Typography>
                        </Stack>
                    ))}

                    <InternalAnswerTable
                        answers={answers}
                        valueCellProps={{ maxRating }}
                        valueCellRenderer={({ value, maxRating }: { value: number; maxRating: number }) => (
                            <Rating max={maxRating} value={Number(value)} readOnly />
                        )}
                    />
                </Stack>
            );
        }
        case QuestionType.SingleSelect: {
            const answerRowsByChoice = groupBy(
                answers.map((answer) => answer),
                "value"
            );
            return (
                <Stack spacing={2}>
                    <BarChart
                        bars={
                            question.options?.map((option) => {
                                return {
                                    title: option,
                                    value: answerRowsByChoice[option]?.length ?? 0,
                                    total: total,
                                };
                            }) ?? []
                        }
                    />
                    <InternalAnswerTable
                        answers={answers}
                        valueCellRenderer={({ value }: { value: string }) => (
                            <Typography variant="textMd">{value}</Typography>
                        )}
                    />
                </Stack>
            );
        }
        case QuestionType.MultiSelect: {
            const allValues = answers.flatMap(({ value }) => value);
            return (
                <Stack spacing={2}>
                    <BarChart
                        bars={
                            question.options?.map((option) => {
                                return {
                                    title: option,
                                    value: allValues.filter((value) => value === option).length,
                                    total: total,
                                };
                            }) ?? []
                        }
                    />
                    <InternalAnswerTable
                        answers={answers}
                        valueCellRenderer={({ value }: { value: string }) => (
                            <Typography variant="textMd">{value}</Typography>
                        )}
                    />
                </Stack>
            );
        }
        case QuestionType.Number: {
            const numberOfAnswers = answers.length;
            const averageValue = answers.map(({ value }) => Number(value)).reduce((a, b) => a + b) / numberOfAnswers;
            return (
                <Stack>
                    <Typography color={(theme) => theme.palette.text.primary} variant="displayMd" marginTop={2}>
                        {Number(averageValue).toFixed(1)}
                    </Typography>
                    <Typography color={(theme) => theme.palette.text.primary} variant="textSm" marginBottom={2}>
                        <FormattedMessage defaultMessage="Average" />
                    </Typography>

                    <InternalAnswerTable
                        answers={answers}
                        valueCellRenderer={({ value }: { value: string }) => (
                            <Typography variant="textMd">{value}</Typography>
                        )}
                    />
                </Stack>
            );
        }
        case QuestionType.Date: {
            return (
                <InternalAnswerList
                    answers={answers}
                    valueCellRenderer={({ value }: { value: string }) => {
                        const date = new Date(value);
                        return <Typography variant="textMd">{formatDateAsUTC(date)}</Typography>;
                    }}
                />
            );
        }
        default: {
            return (
                <InternalAnswerList
                    answers={answers}
                    valueCellRenderer={({ value }: { value: string }) => (
                        <Typography variant="textMd">{value}</Typography>
                    )}
                />
            );
        }
    }
};

export const RoundedLinearProgress = styled(LinearProgress)(({ theme }: { theme: Theme }) => ({
    height: 8,
    borderRadius: 2,
    backgroundColor: theme.palette.grey[300],
}));
