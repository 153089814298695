import { Box, Container, Stack, styled, useScrollTrigger } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { graphql } from "@/gql/gql";
import { Layout_SupplierFragment } from "@/gql/graphql";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { track } from "@/lib/track";

import { ActivityBar } from "../ActivityBar";
import { AlertSnackBar } from "../AlertSnackBar";
import { useKeyboardShortcut } from "../Common/useKeyboardShortcut";

import { Masthead } from "./Masthead";
import { SideNavigation } from "./SideNavigation";
import { useRegisterVisit } from "./useRegisterVisit";

graphql(`
    fragment Layout_Supplier on Supplier {
        ...Masthead_Supplier
        ...ActivityBar_Supplier
    }
`);

interface LayoutProps {
    supplier?: Layout_SupplierFragment;
    loading: boolean;
}

/* Conditional positioning for T3 web/layout refactor transition */
const ConditionallyPaddedStack = styled(Stack)({
    "*:where(html[data-new-layout]) &": {
        top: "var(--appbar-height)",
    },
    "*:where(html:not([data-new-layout])) &": {
        top: "0",
    },
});

const ConditionallyPaddedBox = styled(Box)({
    "*:where(html[data-new-layout]) &": {
        top: "176px",
    },
    "*:where(html:not([data-new-layout])) &": {
        top: "112px",
    },
});

export const Layout: React.FC<LayoutProps> = ({ supplier, loading }) => {
    const [open, setOpen] = useState(false);
    const handleActivityBar = () => {
        track("Supplier Profile: Opened Activity Bar");
        setOpen((prev) => !prev);
    };
    useKeyboardShortcut("a", handleActivityBar);

    useSetBreadcrumbs(supplier?.name || "");

    // Register visit to supplier profile
    useRegisterVisit(supplier);

    const trigger = useScrollTrigger({ threshold: 0 });

    return (
        <Container
            sx={{
                "--masthead-height": "120px",
                display: "flex",
                flexDirection: "column",
                paddingX: 3,
                paddingTop: 2,
            }}
        >
            <ConditionallyPaddedStack
                paddingLeft={1}
                sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                    zIndex: (theme) => theme.zIndex.appBar,
                    borderBottom: (theme) => (trigger ? `1px solid ${theme.palette.divider}` : "1px solid white"),
                    paddingBottom: 3,
                    position: "sticky",
                }}
            >
                <Masthead handleActivityBar={handleActivityBar} supplier={supplier} loading={loading} />
            </ConditionallyPaddedStack>
            <Stack direction="row" position="relative">
                <ConditionallyPaddedBox display="block" height="60vh" paddingRight={3} position="sticky">
                    <SideNavigation scrolling={trigger} />
                </ConditionallyPaddedBox>
                <Outlet />
                {open && supplier && <ActivityBar open={open} handleChange={handleActivityBar} supplier={supplier} />}
                <AlertSnackBar />
            </Stack>
        </Container>
    );
};
