import { Stack, useTheme } from "@mui/material";
import React from "react";
import { Bar, BarChart, CartesianGrid, LabelList, Rectangle, ResponsiveContainer, XAxis, YAxis } from "recharts";

import { formatSpend, getShadeOfLight } from "./utils";

export type DepartmentData = {
    department: string;
    spend: number;
};

type DepartmentChartProps = {
    currency?: string;
    departmentData: DepartmentData[];
};

export const DepartmentChart: React.FC<DepartmentChartProps> = ({ currency, departmentData }) => {
    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ColoredBar = (props: any) => {
        return (
            <Rectangle
                {...props}
                fill={getShadeOfLight(props.index, departmentData.length, theme)}
                className={`recharts-bar-rectangle ${1}`}
            />
        );
    };

    return (
        <Stack>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={departmentData} layout="vertical">
                    <CartesianGrid strokeDasharray="3" horizontal={false} />
                    <YAxis
                        dataKey="department"
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        width={100}
                        tick={{
                            fill: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 14,
                        }}
                        tickFormatter={(value: string) => (value.length < 15 ? value : `${value.substring(0, 12)}...`)}
                    />
                    <XAxis
                        type="number"
                        hide={true}
                        domain={[0, Math.max(...departmentData.map((d) => d.spend)) * 1.3]}
                    />
                    <Bar dataKey="spend" shape={ColoredBar} barSize={32}>
                        <LabelList
                            dataKey="spend"
                            position="right"
                            formatter={(value: number) => formatSpend(value, true, currency)}
                            fontFamily={theme.typography.fontFamily}
                            fontSize={14}
                            fill={theme.palette.grey[600]}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
};
