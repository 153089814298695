import { ActivityBar_SupplierFragment, NoteFragment } from "@/gql/graphql";

import { Activity, ActivityType } from "./types";

export function aggregateActivityData(supplier: ActivityBar_SupplierFragment): Activity[] {
    return [
        {
            content: {
                id: supplier.id,
                name: supplier.name,
                createdAt: supplier.onboarding?.createdAt,
            },
            type: "OnboardingInitiated" as ActivityType,
            timestamp: supplier.onboarding?.createdAt ?? "",
        },
        {
            content: {
                name: supplier.name,
                status: supplier.onboarding?.status,
                approverId: supplier.onboarding?.approverId,
                evaluatedAt: supplier.onboarding?.evaluatedAt,
            },
            type: "OnboardingEvaluated" as ActivityType,
            timestamp: supplier.onboarding?.evaluatedAt ?? "",
        },
        ...supplier.notes.map((note: NoteFragment) => ({
            content: {
                id: note.id,
                note: note.note,
                supplierId: note.supplierId,
                createdBy: note.createdBy,
                createdAt: note.createdAt,
                updatedBy: note.updatedBy,
                updatedAt: note.updatedAt,
                reactions: note.reactions?.map((reaction) => ({
                    emojiCode: reaction.emojiCode,
                    userIDs: reaction.userIDs,
                })),
            },
            type: "Note" as ActivityType,
            timestamp: note.createdAt,
        })),
    ];
}

export function normalizeLocale(locale: string) {
    // Split the locale string at the dash and return the first part
    return locale.split("-")[0];
}
