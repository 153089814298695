import { gql, useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { UpsertContactModal_ContactFragment } from "@/gql/graphql";

graphql(`
    fragment UpsertContactModal_Contact on Contact {
        id
        firstName
        lastName
        email
        position
        phone {
            countryCode
            number
        }
    }
`);

const upsertContactsMutation = graphql(`
    mutation upsertContacts($input: UpsertContactsInput!) {
        upsertContacts(input: $input) {
            result {
                ...SupplierContacts_Contact
            }
        }
    }
`);

interface UpsertContactModalProps {
    contact?: UpsertContactModal_ContactFragment;
    supplierId: string;
    open: boolean;
    onClose: () => void;
}

const validEmailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

export const UpsertContactModal: React.FC<UpsertContactModalProps> = ({ contact, supplierId, open, onClose }) => {
    const { formatMessage } = useIntl();
    const [error, setError] = useState(false);
    const [touched, setTouched] = useState(false);
    const [newContact, setNewContact] = useState({
        email: contact?.email ?? "",
        firstName: contact?.firstName ?? "",
        lastName: contact?.lastName ?? "",
        phone: contact?.phone?.number ?? "",
        position: contact?.position ?? "",
    });
    const isEditing = Boolean(contact);

    const handleFormChange = (event: { target: { id: string; value: React.SetStateAction<string> } }) => {
        if (error && event.target.id == "email" && event.target.value != "") {
            setError(false);
        }
        setNewContact({ ...newContact, [event.target.id]: event.target.value });
        setTouched(true);
    };

    const [upsertContacts] = useMutation(upsertContactsMutation, {
        variables: {
            input: {
                contacts: [
                    {
                        id: contact?.id ?? "",
                        companyId: supplierId,
                        position: newContact.position,
                        firstName: newContact.firstName,
                        lastName: newContact.lastName,
                        email: newContact.email,
                        phone: {
                            number: newContact.phone,
                        },
                    },
                ],
            },
        },
        update: (cache, { data }) => {
            if (data?.upsertContacts?.result) {
                const addedContact = data.upsertContacts.result[0];
                cache.modify({
                    id: cache.identify({
                        __typename: "Supplier",
                        id: supplierId,
                    }),
                    fields: {
                        contacts(existingContacts = []) {
                            const newContactRef = cache.writeFragment({
                                data: addedContact,
                                fragment: gql`
                                    fragment NewContact on Contact {
                                        id
                                        firstName
                                        lastName
                                        email
                                        position
                                        phone {
                                            number
                                        }
                                    }
                                `,
                            });
                            return [
                                ...existingContacts.filter(
                                    (c: { __ref: string | undefined }) => c.__ref !== newContactRef?.__ref
                                ),
                                newContactRef,
                            ];
                        },
                    },
                });
            }
        },
    });
    const handleSubmit = () => {
        if (!validEmailRegex.test(newContact.email)) {
            setError(true);
            return false;
        }
        upsertContacts();
        onClose();
    };
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
                <Stack direction="row" alignItems="space-between">
                    <Typography variant="textLg">
                        {isEditing
                            ? formatMessage({
                                  defaultMessage: "Edit contact",
                                  description: "Edit contact title",
                              })
                            : formatMessage({
                                  defaultMessage: "Add contact",
                                  description: "Add contact title",
                              })}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={3}>
                    <TextField
                        margin="dense"
                        id="email"
                        label={formatMessage({ defaultMessage: "Email" })}
                        type="email"
                        fullWidth
                        value={newContact.email}
                        onChange={handleFormChange}
                        error={error}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="firstName"
                        label={formatMessage({ defaultMessage: "First Name" })}
                        type="text"
                        fullWidth
                        value={newContact.firstName}
                        onChange={handleFormChange}
                    />

                    <TextField
                        margin="dense"
                        id="lastName"
                        label={formatMessage({ defaultMessage: "Last Name" })}
                        type="text"
                        fullWidth
                        value={newContact.lastName}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="phone"
                        label={formatMessage({ defaultMessage: "Phone" })}
                        type="text"
                        fullWidth
                        value={newContact.phone}
                        onChange={handleFormChange}
                    />
                    <TextField
                        margin="dense"
                        id="position"
                        label={formatMessage({ defaultMessage: "Position" })}
                        type="text"
                        fullWidth
                        value={newContact.position}
                        onChange={handleFormChange}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ mt: "auto" }}>
                <Stack direction="row" justifyContent="flex-end" spacing={1} width="100%">
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                    </Button>
                    <Button type="submit" onClick={handleSubmit} disabled={!touched}>
                        {isEditing ? (
                            <FormattedMessage defaultMessage="Save" description="Save button" />
                        ) : (
                            <FormattedMessage defaultMessage="Add" description="Add button" />
                        )}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
