import { useState, useEffect } from "react";

const getIsSmallScreen = () => window.innerWidth < 1280;

export default function useIsSmallScreen() {
    const [isSmallScreen, setIsSmallScreen] = useState(getIsSmallScreen());

    useEffect(() => {
        const onResize = () => {
            setIsSmallScreen(getIsSmallScreen());
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    return isSmallScreen;
}
