import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Bar, BarChart, CartesianGrid, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { DatePeriodSelection } from "@/components/Common/spendUtils";
import { DateInterval } from "@/gql/graphql";

import { formatSpend, getMonth } from "./utils";

export type TransactionData = {
    period: string;
    transactions: number;
    avgValue: number;
};

type TransactionChartProps = {
    period: DatePeriodSelection;
    currency?: string;
    transactionData: TransactionData[];
};

export const TransactionChart: React.FC<TransactionChartProps> = ({ period, currency, transactionData }) => {
    const theme = useTheme();

    const [isBarHovered, setIsBarHovered] = useState(false);
    const handleMouseOver = () => setIsBarHovered(true);
    const handleMouseOut = () => setIsBarHovered(false);

    return (
        <Stack onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart data={transactionData}>
                    <CartesianGrid strokeDasharray="3" vertical={false} />
                    <XAxis
                        dataKey="period"
                        axisLine={false}
                        tickLine={false}
                        tick={{
                            fill: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 14,
                        }}
                    />
                    <YAxis
                        axisLine={{ stroke: "transparent" }}
                        tickLine={false}
                        tickFormatter={(value) => formatSpend(value, true)}
                        tick={{
                            fill: theme.palette.grey[600],
                            fontFamily: theme.typography.fontFamily,
                            fontSize: 14,
                        }}
                    />
                    <Tooltip
                        content={({ payload }) => {
                            const data = payload?.[0]?.payload;
                            return (
                                <Card sx={{ minWidth: 150, position: "relative" }}>
                                    <Stack margin={1}>
                                        <Typography variant="textSm" marginLeft={0.5}>
                                            {period.interval == DateInterval.Month
                                                ? getMonth(data?.period, "long")
                                                : data?.period}
                                        </Typography>
                                        <Stack direction="row" spacing={1} marginY={0.5}>
                                            <Box
                                                sx={{
                                                    top: 16,
                                                    bottom: 16,
                                                    left: 8,
                                                    width: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: theme.palette.secondary.main,
                                                }}
                                            />
                                            <Stack>
                                                <Typography variant="textXs">
                                                    <FormattedMessage defaultMessage="Average transaction value" />
                                                </Typography>
                                                <Typography variant="textLg">
                                                    {formatSpend(data?.avgValue ?? 0, true, currency)}
                                                </Typography>
                                                <Typography variant="textXs" marginTop={1}>
                                                    <FormattedMessage defaultMessage="Number of transactions" />
                                                </Typography>
                                                <Typography variant="textLg">{data?.transactions}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Card>
                            );
                        }}
                        cursor={{ fill: "transparent" }}
                    />
                    <Bar
                        dataKey="avgValue"
                        fill={theme.palette.secondary.main}
                        opacity={isBarHovered ? 0.6 : 1}
                        activeBar={<Rectangle opacity={1} fill={theme.palette.secondary.main} />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
};
