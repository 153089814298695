import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { graphql } from "@/gql/gql";
import { Layout_SupplierFragment } from "@/gql/graphql";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { track } from "@/lib/track";

import { ActivityBar } from "../ActivityBar";
import { useKeyboardShortcut } from "../Common/useKeyboardShortcut";

import { Masthead } from "./Masthead";
import { SideNavigation } from "./SideNavigation";
import { useRegisterVisit } from "./useRegisterVisit";

graphql(`
    fragment Layout_Supplier on Supplier {
        ...Masthead_Supplier
        ...ActivityBar_Supplier
    }
`);

interface LayoutProps {
    supplier?: Layout_SupplierFragment;
    loading: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ supplier, loading }) => {
    const [open, setOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [sideNavPosition, setSideNavPosition] = useState(0);
    const handleActivityBar = () => {
        track("Supplier Profile: Opened Activity Bar");
        setOpen((prev) => !prev);
    };
    useKeyboardShortcut("a", handleActivityBar);

    useSetBreadcrumbs(supplier?.name || "");

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setIsScrolling(scrollTop > 0);
        // divide by 2 to slow down the scroll effect
        setSideNavPosition(event.currentTarget.scrollTop / 2 > 24 ? 24 : event.currentTarget.scrollTop / 2);
    };

    // Register visit to supplier profile
    useRegisterVisit(supplier);

    return (
        <Stack alignItems="center" padding="0 24px" marginTop={2}>
            <Stack
                maxHeight="calc(100vh - 88px)"
                minHeight="calc(100vh - 88px)"
                height="100%"
                boxSizing="border-box"
                width="100%"
                maxWidth="1536px"
                position="relative"
                overflow="hidden"
            >
                <Stack
                    paddingLeft={1}
                    sx={{
                        position: "sticky",
                        top: 0,
                        borderBottom: (theme) =>
                            isScrolling ? `1px solid ${theme.palette.divider}` : "1px solid white",
                        paddingBottom: 3,
                    }}
                >
                    <Masthead handleActivityBar={handleActivityBar} supplier={supplier} loading={loading} />
                </Stack>
                <Stack
                    direction="row"
                    columnGap={3}
                    height="100%"
                    flexGrow={1}
                    sx={{ overflowY: "auto" }}
                    onScroll={handleScroll}
                >
                    <SideNavigation position={sideNavPosition} />
                    <Stack width="100%">
                        <Outlet />
                        {open && supplier && (
                            <ActivityBar open={open} handleChange={handleActivityBar} supplier={supplier} />
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
